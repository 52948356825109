.book-section-container{
    padding: 0 0.8rem ;
    height: 100%;
    text-align: center;
}

.books-back{
    display: flex;
    align-items: center;
    padding: 0.4rem;
    margin: 1rem 0;
    background: #FFC28A;
    width: fit-content;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;
}