.shopping-container{
    background: #FFF2E6;
    padding: 1rem;
    width: 94%;
    margin: 0.8rem auto;
    box-shadow: 0px 2px 5px 0px #44097826;
    border-radius: 0.4rem;
    position: relative;
}
.shopping-container .design{
    width: 16%;
    position: absolute;
}
.shopping-container .heading{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.shopping-container .heading .info{
    flex: 0 0 50%;
    line-height: 1.5;
}
.info p{
    font-weight: 400;
    opacity: 0.6;
}
.shopping-container .heading .input{
    flex: 0.8;
    text-align: right;
}

.shopping-container .heading input{
    padding: 0.8rem;
    width: 80%;
    border-radius: 0.4em;
    outline: none;
    border: 2px solid #C5B3D5;
}
.shopping-container .heading input::placeholder{
    opacity: 0.5;
}
