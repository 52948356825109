.question-filter-popup{
    /* position: absolute; */
    background: #fff;
    top: 14%;
    right: 4%;
    border: 1px solid #BABBBC;
    border-radius: 0.4em;
    padding: 1rem;
    position: fixed;
}
.question-filter-popup .close{
    padding: 0;
    border: none;
    margin: 0;
    width: 100%;
    display: block;
    text-align: right;
}
.occurence-list{
    margin-top: 0.4rem;
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.occurence-list label{
    display: flex;
    gap: 0.4rem;
}
.occurence-list input{
    padding: 0.8rem;
}