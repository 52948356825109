.profile-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  gap: 1rem;
}

.user-profile-container {
  /* width: 100vw; */
  margin: 2rem 0;
}

.user-profile-detials-box {
  width: 20vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgb(0, 0, 0, 0.2);
  padding: 1rem;
}

.save-profile {
  width: 60%;
  padding: 0.6rem;
  border-radius: 10px;
  background-color: #d6e9e1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  align-self: center;
  cursor: pointer;
}

.customize-profile {
  width: 60%;
  padding: 0.8rem;
  border-radius: 10px;
  background-color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 1rem;
  align-self: center;
  cursor: pointer;
}

.logout-profile {
  width: 60%;
  padding: 0.6rem;
  border-radius: 10px;
  background-color: red;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 1rem;
  align-self: center;
  cursor: pointer;
}


.user-profile-data {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-profile-data input {
  width: 90%;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-div {
  border: 1px solid;
  padding: 0.3rem;
  border-radius: 10px;
}

.input-div input {
  outline: none;
}

.input-div i {
  cursor: pointer;
}

.user-dash-profile-img {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: 2px solid #349367;
  overflow: hidden;
}

.user-dash-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.focused {
  border: 2px solid #349367;
}


.user-dropdowns{
  background-color: white;
  width: 90%;
  border: none;
  padding: 0.3rem;

}