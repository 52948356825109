.rating-reviews-container {
  display: flex;
  gap: 1rem;

}
.rating-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 20px;
  /* height: 16rem; */
  background: rgba(255, 255, 255, 0.8);

  border: 1px solid rgba(213, 197, 179, 1);
}
/* UserRating.css */

.user-reviews-container{
    /* height: 16rem; */
    border-radius: 20px ;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(213, 197, 179, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1.5rem;
}

.average-rating h4 {
  margin: 0;
  font-size: 44px;
  font-weight: 500;
}
.average-rating p {
  font-size: 14px;
  font-weight: 400;
  text-align: start;
}
.star-rating {
  display: flex;
}

.star-rating img {
  width: 20px;
  height: 20px;
}

.ratings {
  display: flex;
  flex-direction: column;
}

.rating-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 1rem;
}
.rating-item h1 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.rating-bar {
  width: 18.75rem;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-right: 10px;
}

.rating-bar-fill {
  height: 100%;
  border-radius: 5px;
  background-color: rgba(176, 228, 221, 1);
}

.rating-count {
  font-size: 14px;
}
.user-reviews-container h1{
    margin: 0;
    background: rgba(255, 214, 176, 1);
    margin-top: -3rem;
    padding: 1.2rem;
    font-size: 22px;
    font-weight: 600;

border-radius: 20px ;
display: flex;
align-items: center;
justify-content: center;
}

.user-reviews-all-reviews-container{
    display: flex;
  
}
.review-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-name {
    font-size: 14px;
    font-weight: 400;  
  color: rgba(68, 9, 120, 1);
}

.user-rating {
  font-size: 14px;
  font-style: italic;
  color: #666;
}

.review-comment {
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

}
@media (min-width: 1366px) and (max-width: 1367px) {
  .rating-container {
    width: 12rem;
  }
}