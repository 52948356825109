.subject-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    margin: 0 1rem;
    // gap: 4rem;
    border-radius: 0.4em;
    border: 1px solid #aeaead;
}

.subject-image img{
    // width: 100%;
    // height: 100%;
    object-fit: cover;
    border-radius: 20px;
    width: 17rem;
}
.subject-card h3{
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: bolder;
}
.subject-card p{
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #02720d;
}

.lock-image{
    position: absolute;
}