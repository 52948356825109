/* Calendar.css */

.custom-calendar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: snow;
}

.custom-calendar-header {
  padding: 20px;
}
.custom-calendar.blurred {
  filter: blur(1rem); /* Adjust the blur strength as needed */
}

.custom-calendar-content {
  gap: 2rem;
  padding: 2rem;
  display: flex;
}

.custom-sidebar {
  padding: 1rem;
  /* width: 29rem; */
border-radius: 10.79px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(242, 236, 230, 1);
box-shadow: 0px 3.6px 28.77px 0px rgba(170, 170, 170, 0.03);
}

/* Add styles for event details background */
.calendar-event-details-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  cursor: pointer; /* Show pointer cursor when hovering over */
}

/* Add styles for event details container */
.calendar-event-details-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 34rem; /* Adjust as needed */
  background: white;
  z-index: 1000; /* Ensure it's above the background */
  overflow-y: auto; /* Allow scrolling if content exceeds container height */
}
.custom-calendar-content-header-month-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}