.jobs-container {
  padding-left: 2rem;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}
.jobs-container-best-jobs-await-container{
  background: #ECE6F2;
   
  height: 25rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 2rem;
}
.applied-jobs-heading{

font-size: 30px;
font-weight: 700;

}
.applied-jobs-container{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.jobs-container-best-jobs-await-text-heading{
    margin: 0;
  font-size: 30px;
  font-weight: 500;
  
  
  }
  .jobs-container-best-jobs-await-text-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .jobs-container-best-jobs-await-text-paragraph{
      width: 85%;
      text-align: start;
font-size: 18px;
font-weight: 300;
color: rgba(0, 0, 0, 1);
opacity: 60%;
  }
 
  .jobs-container-best-jobs-await-next-section-arrow{
      width: 10rem;
      height: 5rem;
      opacity: 0px;
      background: #FFFFFF;
          border-radius: 50%; 
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .jobs-container-best-jobs-await-next-section-arrow:hover, .jobs-container-applied-jobs-next-section-arrow:hover{
    background-color: rgba(217, 242, 239, 1);
  }
  .jobs-container-applied-jobs-next-section-arrow{
    width: 6rem;
    height: 6rem;
    opacity: 0px;
    background: #FFFFFF;
        border-radius: 50%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.jobs-container-best-jobs-await-card-container{
  display: flex;
  gap: 1rem;
  margin-right: 2rem;
}



.jobs-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.job-search-and-save {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}



.saved-jobs{
  font-size: 1.3em;
}
.saved-jobs:hover{
  cursor: pointer;
  
}

.bookmark{
  cursor: pointer;
}

.bookmark i {
  margin-right: 0.5rem;
}

.jobs-filter {
  width: 20%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  /* top: 5rem; */
  margin-bottom: 1em;
}

.jobs-filter-options {
  align-self: flex-start;
  margin-bottom: 1em;

}

.jobs-filter-options p {
  text-align: start;
  padding-left: 2rem;
  font-weight: bold;
  
}

.jobs-and-filter {
  /* width: 80vw; */
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}
.filter{
  margin-top: 1em;
}
.checkboxes {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2rem;
  font-size: 1rem;
  padding: .4em;
}

.checkboxes input {
  width: 2rem;
  height: 1.3rem;
  accent-color: #349367;
}

.checkboxes label {
  justify-content: flex-start;
}

.applied-jobs-filter{
  width: 106px;



border-radius: 10px;
border: 2px solid rgba(197, 179, 213, 1);
background: rgba(255, 255, 255, 1);
display: flex;
align-items: center;
justify-content: space-around;
cursor: pointer;
}
.applied-jobs-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.applied-jobs-card-container{
  display: flex;
  gap: 1rem;
  align-items: center;
}
.jobs-container-applied-jobs-next-section-arrow span{
font-size: 14px;
font-weight: 400;
color:rgba(0, 59, 51, 1);
}