.experts-page {
  background: #ece6f2;
  padding: 2rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 1rem;

}

.experts-page-byteconnect-forge-path-container {
  background: #ece6f2;

  border-radius: 10px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 0 2rem;
}
.experts-page-byteconnect-booked-session-container {
  margin-top: 1rem;
  flex-direction: column;
  align-items: start;
}
.experts-page-byteconnect-forge-path-text-paragraph {
  width: 55%;
}
.experts-page-byteconnect-forge-path-search-bar {
  margin-right: 4rem;
}
.experts-page-byteconnect-forge-path-text-search-container {
  display: flex;
}
.experts-container {
  display: grid;
  margin: 0 auto;
  max-width: 96rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 0.5rem;
  margin-top: 1rem;
}
.experts-page-byteconnect-forge-path-text-search-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.experts-page-buttons {
  height: 50px;
  padding: 13px 37px 13px 37px;
  gap: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  border: none;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.experts-page-buttons.active {
  background: rgba(255, 149, 51, 1); 
}

.experts-page-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}
.expert-page-filter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 6rem;
  height: 3rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(197, 179, 213, 1);
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 1366px) and (max-height: 768px) {
  .experts-page-buttons {
    font-weight: 400;
    padding: 13px 14px 13px 14px;
    font-size: 16px;
  }
  .experts-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
  }
  .experts-page-byteconnect-forge-path-search-bar {
    width: 149%;
    margin-right: 0rem;
  }
  .expert-page-filter {
    width: 23rem;
  }
  .student-card-image2 {
    height: 5.5rem !important;
    margin-top: -1.7rem;
  }
  .experts-page-byteconnect-forge-path-text-paragraph {
    width: 89% !important;
  }
}

@media (min-width: 1920px) and (min-height: 1080px) {
  .experts-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
    margin-top: 1rem;
  }
  .student-card-image2 {
    height: 5.5rem !important;
    margin-top: -1.7rem;
  }
}
