.expert-meetings-container {
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(68, 9, 120, 0.15);
  background: rgba(255, 255, 255, 1);
  padding-bottom: 3rem;

}
.expert-meetings-header-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 25px 18px 25px 25px;
  gap: 1rem;
  border-radius: 20px 20px 0px 0px;
  background: rgba(197, 179, 213, 1);
  border-bottom: 1px solid rgba(107, 52, 0, 1);
}
.expert-meetings-cards-container h2{
  margin-top: 1rem;
font-size: 30px;
font-weight: 500;

  
}
.expert-card-timing-text-container p{

font-size: 14px;
font-weight: 400;



}
.expert-meeting-rating-text-container p{
  
font-size: 14px;
font-weight: 400;


}
.expert-meeting-about-mentorship-container p{
  
font-size: 16px;
font-weight: 400;


}
.expert-card-rate-text{
  color: rgba(7, 184, 24, 1);
 
  font-size: 16px;
  font-weight: 700;

  
}

.expert-button-style {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
  padding: 13px 30px 13px 30px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 400;
}
.expert-meeting-card {
padding: 1rem;
  background: rgba(227, 218, 235, 1);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 1rem;
}
.expert-meeting-card-deco {
  width: 53px;
  height: 58px;
  top: -65px;
  left: 300px;
  gap: 0px;
  border: 40px 0px 0px 0px;
  opacity: 0px;
  border: 40px solid rgba(236, 230, 242, 1);
  border-radius: 25rem;
  position: absolute;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  z-index: 1;
  transform: rotate(90deg);
  top: -23px;
  left: 18rem;
}
.expert-meeting-particular-section-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 1rem;
}
.expert-meeting-rating-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.expert-card-timing-and-rate-container {
  display: flex;
}
.expert-meeting-rating-popular-text {
  padding: 8px;
  border-radius: 10px;
  opacity: 0px;
  background: rgba(218, 243, 250, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
}
.expert-meeting-rating-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
}
.expert-meeting-mentorship-type-text {
  font-size: 30px;
  font-weight: 500;
}
.expert-card-timing-and-rate-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 376px;
  height: 60px;
  border-radius: 10px;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
}
.expert-card-timing-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.expert-card-rate-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;

  padding: 8px 9px 8px 10px;
  border-radius: 10px;
  border: 1px solid rgba(7, 184, 24, 1)
}
.expert-card-timing-text-container{
    margin-left: 10px;
}
.expert-meeting-about-mentorship-container{
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}
.expert-meetings-cards-container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
 
}


@media (max-width: 1366px)and (max-height: 768px) {
  .expert-meetings-header-buttons-container, .expert-card-timing-and-rate-container  {
    width: 94%;
}
.expert-meeting-card {
  width: 94%;
  padding-bottom: 1rem;
}
.expert-card-timing-and-rate-container  {
  height: 59px;
}
.expert-meeting-mentorship-type-text {
  font-size: 24px;
  font-weight: 500;
}
.expert-card-time-text, .expert-card-meeting-type-text{
  font-size: 0.7rem;
}
}

@media (min-width: 1920px) and (min-height: 1080px) {
  .expert-meetings-container {
    
    width: 53rem;
  }
  .expert-meeting-card {
    width: 23.5rem;
    height: 19rem;
}
.expert-card-timing-and-rate-container {
 
  width: 22.5rem;
  height: 60px;
 
}
}