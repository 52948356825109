body{
  background: #fff;
}


  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  a{
    text-decoration: none;
    color: #000;
  }
  li{
    list-style:none;
  }

  .dashboard-main-container {
    font-family: "Poppins", sans-serif;
    display: flex;
    height: 100vh;
  }
  
  .dashboard-content {
    display: flex;
    flex-direction: column;
    flex: 0 0 86%;
  }
  .dashboard-content::-webkit-scrollbar{
    display: none;
  }
  .dashboard {
    display: flex;
    flex-direction: row;
  }
  
  .side-nav-bar {
    background-color: black;
    color: white;
  }
  
  .subject {
    width: 4em;
    height: 4em;
    background-color: green;
    margin: 1em;
  }
  .subject :hover {
    cursor: pointer;
  }
  .dashboardHeader {
    padding-top: 2em;
    background-color: green;
  }
  
  .main-content::-webkit-scrollbar{
    display: none;
  }
  