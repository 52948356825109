.profile-courses-container {
  /* width: 70vw; */
  margin-bottom: 2rem;
}

.profile-courses-details {
  /* width: 100%; */
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.courses-certi-and-name {
  display: flex;
  gap: 1rem;
  align-items: center;
  
}

.ongoing-courses-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ongoing-courses-card {
  /* width: 100%; */
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e2e7e7;
  padding: 1rem;
  border-radius: 10px;
  font-size: 1rem;
}

.end-courses {
  padding: 0.8rem;
  background-color: #cd2626;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.certi-img {
  background-color: white;
  padding: 0.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}
.certi-img img{
  overflow: auto;
}

.go-and-download-courses{
  /* width: 30%; */
  display: flex;
  gap: 1rem;
}

.go-to {
  background-color: #02720d;
  padding: 0.8rem;
  border-radius: 10px;
  width: 8rem;
  text-align: center;
  border: 1px solid grey;
  cursor: pointer;
  color: white;
}

.download{
  background-color: #5ea8866e;
  padding: 0.4rem;
  border-radius: 10px;
  border: 1px solid grey;
  cursor: pointer;
}

.completed-and-end-course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-bottom: 1rem;
}
