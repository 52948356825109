.booking-confirmed-page {
  height: 90.5vh;
  width: 100%;
  background: rgba(236, 230, 242, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-confirmed-container {
  border-radius: 20px;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  gap: 9rem;
}
.booking-confirmed-details-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.booking-confirmed-header-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  border-bottom: 1px solid rgba(197, 179, 213, 1);
}
.booking-confirmed-header-details h3 {
  font-size: 20px;
  font-weight: 400;

  cursor: pointer;
}
.booking-confirmed-details-description-body h1 {
  font-size: 30px;
  font-weight: 700;
}
.booking-confirmed-details-description-body p {
  font-size: 20px;
  font-weight: 400;
}
.tick-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-confirmed-page-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(230, 246, 244, 1);
  width: 850px;
  height: 60px;
  color: rgba(68, 9, 120, 1);

  font-size: 18px;
  font-weight: 400;
}
.booking-confirmed-details-description {
  padding: 19px 30px 19px 30px;

  border-radius: 10px;
  background: rgba(236, 230, 242, 1);
  border: 1px solid rgba(68, 9, 120, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking-confirmed-details-description-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.tick-container img {
  width: 25%;
  margin-top: -6rem;
  margin-bottom: -2rem;
}
@media (min-width: 1920px) and (min-height: 1080px) {
  .booking-confirmed-page {
    height: 92vh;
  }
}
