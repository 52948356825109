.chapter-card-container{
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(213, 197, 179, 1);
    border-radius: 20px;
    gap: 30rem;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chapter-card-text-container{
display: flex;
flex-direction: column;
align-items: start;
}
.chapter-card-text-container h2{
margin: 0;
font-size: 30px;
font-weight: 500;

}
.chapter-card-text-container p{
    font-size: 20px;
    font-weight: 400;
}
.chapter-card-resume-button{
    background: rgba(7, 184, 24, 1);
    width: 13rem;
    height: 3rem;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 1366px)and (max-height: 768px) {
    .chapter-card-container {
        gap: 6rem;    
    }
}