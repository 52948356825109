.about-expert-card-container{
    width: 37.7rem;
    height: 63rem;
    border-radius: 20px ;
    box-shadow: 0px 2px 10px 0px rgba(68, 9, 120, 0.15);
    background: rgba(255, 255, 255, 1);


}
.about-expert-expert-name{
    font-size: 30px;
    font-weight: 500;
    color: rgba(4, 61, 77, 1);
}
.about-expert-expert-role{
  
    font-size: 18px;
    font-weight: 400;
    color: rgba(4, 61, 77, 1);
    
}
.about-expert-details-summary p{
    font-size: 16px;
    font-weight: 400;

    
}
.about-expert-skills-section{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.expert-details-skills-text{
font-size: 20px;
font-weight: 400;


}
.about-expert-byte-verified-container{
    position: relative;
    top: -3.5rem;
    float: right;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(7, 184, 24, 1);
    color:white;width: Fixed (218px)px;
   
    padding: 7.47px 5px 7.47px 5px;
    gap: 7.47px;
    border-radius: 7.47px;
}
.expert-details-skills-single-container{
    display: flex;
    align-items: center;
    justify-content: center;
padding: 13px 8px 13px 8px;
gap: 10px;
border-radius: 5px;
border: 1px ;
opacity: 0px;
background: rgba(250, 250, 250, 1);
border: 1px solid rgba(0, 0, 0, 1)
}
.expert-details-all-skills-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Two columns */
    gap: 0.5rem; /* Gap between grid items */
    margin-top: 1rem;
}
.about-expert-text-container{
    padding-left: 1rem;
    padding-right: 1rem;
}
@media (max-width: 1366px)and (max-height: 768px) {
    .about-expert-card-container, .about-expert-image {
        width: 25rem;
    }
    .expert-details-skills-single-container {
      
        width: 6.6rem;
        padding: 4px 4px 4px 4px;
        font-size: 11px;
    }
    

}