/* SmallCalendar.css */

.small-calendar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  }
  
  .small-calendarcalendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 178%; */
  }
  
  .small-calendarcalendar-title {
    color: #4b5563; /* Equivalent to text-gray-500 with Tailwind */
    font-weight: bold;
  }
  
  .small-calendarnavigation-buttons {
    display: flex;
  }
  
  .small-calendarnav-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .small-calendarchevron-left,
  .small-calendarchevron-right {
    color: #6b7280; /* Equivalent to text-gray-600 with Tailwind */
    cursor: pointer;
  }
  
  .small-calendardays-grid {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: auto;
    gap: 2.5rem;
  }
  
  .small-calendarday-label {
    text-align: center;
    color: #6b7280; /* Equivalent to text-gray-600 with Tailwind */
  }
  
  .small-calendarday-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
font-size: 15px;
font-weight: 400;
color: rgba(102, 102, 102, 1);

  }
  
  .small-calendarcurrent-day {
    background-color: #3b82f6; /* Equivalent to bg-blue-500 with Tailwind */
    color: #fff; /* Equivalent to text-white with Tailwind */
    border-radius: 9999px;
  }
  
  .small-calendarselected-day {
    background-color: #dbeafe; /* Equivalent to bg-blue-100 with Tailwind */
    color: #2563eb; /* Equivalent to text-blue-600 with Tailwind */
    font-weight: bold;
  }
  .small-calendar-navigation-buttons button{
    background: none;
    border: none;
  }