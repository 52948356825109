.robot-ask img{
    width: 15rem;
}


.robot-ask{
    transform: translateX(5rem);
}

.red-circle{
    position: absolute;
    width: 500px;
    height: 500px;
    background-color: rgb(238, 111, 111);
    border-radius: 50%;
    top: -10rem;
    z-index: 111;
}