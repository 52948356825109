.current-book-container{
    width: 100%;
  height: 30vh;
  position: relative;
  border-radius: 0.4rem;
  margin: 1rem 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.filter-books-tags {
  margin: 2rem auto;
  width: 90%;
  
}

.allBooks-container{
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.read-button{
    margin-top: 1rem;
    background: #ff9533;
  width: fit-content;
  padding: 0.4rem 0.8rem;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.library-book-card-container{
    display: flex;
    justify-content: flex-start;
    overflow-y: scroll;
    gap: 1rem;
    margin-top: 1rem;
}
.library-book-card-container::-webkit-scrollbar{
    display: none;
}
.book-card{
    display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex: 0 0 24%;
  border-radius: 0.4rem;
  justify-content: space-between;
  position: relative;
}
.book-bg{
    position: absolute;
    height: 70%;
    z-index: -1;
}
.book-bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: opacity(0.4);
}
.book-card-image{
    display: flex;
    justify-content: center;
    background: #D5F1F9;
    padding: 0.8rem;
}
.book-card-image img{
    object-fit: cover;
    width: 50%;
}
.book-card-info{
    padding: 0.8rem;
}
.book-card-info .book-title{
    font-size: 1.4rem;
    font-weight: 700;
}
.book-card-info .description{
    font-size: 0.8rem;
    font-weight: 400;
}
.read-download-book{
    padding: 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
}
.read-book{
    background: #FFA754;
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    cursor: pointer;
}
.download-book{
    background: #FFF2E6;
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    cursor: pointer;
}
.books-view-all-button {
    display: flex;
    flex-direction: column;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.books-view-all-button p{
font-size: 11.08px;
font-weight: 400;
color: rgba(0, 59, 51, 1);
}
.books-view-all-button button{
    width: 6rem;
    height: 6rem;
    /* border-radius: 60px 60px 60px 60px; */
        border-radius: 50%; /* This will make the element a perfect circle */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: none;
}
.books-view-all-button:hover{

  background-color: rgba(217, 242, 239, 1);
}
.book-card-history-recent{
    display: flex;
    gap: 0.4rem;
    flex: 0 0 31%;
    border-radius: 0.4rem;
    justify-content: space-between;
    position: relative;
}