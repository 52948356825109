.expert-meeting-details-page{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
}
.timezone-dropdown{
font-size: 20px;
font-weight: 400;
border: none;
width: 53rem;
}
.calendar-header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.meetings-detail-right-confirm-details-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.submit-button{
    width: 45rem;
    height: 71px;
    padding: 20px;
    border-radius: 10px;
    background: rgba(7, 184, 24, 1);
color: white;
font-size: 22px;
font-weight: 600;
cursor: pointer;

}
.choose-calendar{
    color: rgba(68, 9, 120, 1);
    font-size: 18px;
    font-weight: 400;
   
    cursor: pointer;
}