.commentSection-comment-container button{
    background: #C5B3D5;
}
/* .comment-Section{
    width: 76rem;
} */
.commentSection-picture{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.commentSection-picture img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
.commentSection-commentArea{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.commentSection-name-comment h4{
    margin: 0;
font-size: 14px;
font-weight: 400;

color: rgba(68, 9, 120, 1);

}
.commentSection-name-comment p{
    text-align: start;
font-size: 18px;
font-weight: 400;



}
.commentSection-commentArea input{
    padding: 0.8rem;
    width: 83%;
    border-radius: 0.4rem;
    border: 1px solid #C5B3D5;
}
.commentSection-commentArea input::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 0.5; /*Change the opacity between 0 and 1*/
}
.commentSection-commentArea input:focus {
  outline: none;
}
.commentSection-commentArea button{
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 0.4em;
    background: #440978;
    color: #fff;
}
.commentSection-commentSection{
    margin-top: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #DFE0E1;
    padding: 0.8rem;
}
.commentSection-comment-list{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
}
.commentSection-profile-name{
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.commentSection-timestamp{
    font-size: 0.6rem;
    opacity: 0.5;
}
.commentSection-name-comment{
    display: flex;
    justify-content: space-between;

    align-items: center;
}
.commentSection-name-timestamp{
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.commentSection-name-timestamp .name{
    font-size: 1.2rem;
}
.commentSectiontimestamp{

font-size: 18px;
font-weight: 400;
color: rgba(0, 0, 0, 1);

}