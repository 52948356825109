.current-book-cover{
    width: 16%;
    height: 80%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 2rem;
}

.current-book-title{
    color: #fff;
    width: 26%;
}
.book-card-history{
    display: flex;
    /* flex-direction: column; */
    gap: 0.4rem;
    flex: 0 0 24%;
    border-radius: 0.4rem;
    justify-content: space-between;
    position: relative;

}

