.blogs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-head {
  border: 1px solid;
  text-align: center;
}

.blog-head p {
  text-align: center;
}

.blog-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-desc p {
  font-size: 18px;
  text-align: center;
  position: relative;
}

.blog-cards-container {
 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.blog-cards {
  border: 1px solid;
  border-radius: 20px;
  width: 18rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  /* margin: 1rem 0 2rem 0; */
  transition: all 0.3s linear;
  /* margin: 2em; */
  padding: .3rem;
  background-color: white;
}

.blog-cards:hover {
  transform: scale(1.1);
  background-color: aliceblue;
}

.blog-square-img {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-square-img img {
  max-width: 100%;
  margin: 1rem;
  object-fit: "cover";
  border-radius: 20px;
}

.blog-title p {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  color: black;
  text-align: start;
}

.blog-data-desc p{
  font-size: 14px;
  color: rgba(145, 145, 145, 1);
  text-align: start;
}

.blog-data {
  width: 90%;
  display: flex;
  flex-direction: column;;
  gap: .5rem;
  align-items: start;
}

.views-comments {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.views-comments p {
  font-size: 14px;
  color: #817777;
}

.blog-globe {
  position: absolute;
  top: 58px;
  right: 381px;
  transform: rotate(-13deg);
}
