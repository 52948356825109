.single-question-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.backtoprevious:hover{
    cursor: pointer;
}
.back-next{
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        font-size: 1rem;
    }
    
}
.back-next button{
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 0.4rem;
    background: none;
    border: none;
    transition: 300ms ease-in;
    cursor: pointer;
    padding:  0.8rem;
    border-radius: 0.4rem;
    
}
.back-next button:hover{
    background: #c5b3d5c8;
}
.question-hint-container{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.question-comment-section{
    padding: 1rem;
    border: 1px solid #DFE0E1;
    border-radius: 0.4em;
}

.question-comment-section .question{
    /* border-bottom:1px solid #0000002f ; */
}

.question ol li{
    list-style: upper-alpha;
}

.answer{
   color: #440978;
   font-weight: 600;
}
.comment-container button{
    background: #C5B3D5;
}
.picture{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
.picture img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
.commentArea{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.commentArea input{
    padding: 0.8rem;
    width: 85%;
    border-radius: 0.4rem;
    border: 1px solid #C5B3D5;
}
.commentArea input::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 0.5; /*Change the opacity between 0 and 1*/
}
.commentArea input:focus {
  outline: none;
}
.commentArea button{
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 0.4em;
    background: #02720d;
    color: #fff;
}

button:hover{
    cursor: pointer;
}


.commentSection{
    margin-top: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #DFE0E1;
    padding: 0.8rem;
    
}
.comments{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 1rem;
}
.comment{
    display: flex;
    align-items: start;
    justify-self: start;
    margin-top: 1rem;
}

.timestamp{
    font-size: 14px;
    font-weight: 400;
}
.comment-text{

    font-size: 18px;
    font-weight: 400;
   
    
}
.profile-name{
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.timestamp{
    font-size: 0.6rem;
    opacity: 0.5;
}
.name-comment{
    display: flex;
    flex-direction: column;
}
.name-timestamp{
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.name-timestamp .name{
    font-size: 14px;
    font-weight: 400;
    color: rgba(68, 9, 120, 1);
}
.hint-explanation-section{
    padding: 1rem;
    border: 1px solid #DFE0E1;
    border-radius: 0.4em;
}


.accordion .hints-title{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #440978;
    font-weight: 600;
    padding: 0.8rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    &:hover{
        cursor: pointer;
    }
}


.hints-title{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #440978;
    font-weight: 600;
    padding: 0.8rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    &:hover{
        cursor: pointer;
    }
}



.accordion{
    border: 0.94px solid #ECE6F2;
    box-shadow: 0px 0.94px 1.88px 0px #44097826;
    border-radius: 0.4rem;
    margin: 1rem;
}
.accordion .hints-title{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #440978;
    font-weight: 600;
    padding: 0.8rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    &:hover{
        cursor: pointer;
    }
}

.accordion div{
    padding: 0.8rem;
}
.hidden-gradient{
    position: relative;
    border-radius: 0.4rem;
}
.lock{
    background: #000000db;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 50%;
    color: #fff;
    border-radius: 0.4rem;
}
.lock p:nth-child(1){
    font-weight: 600;
    padding: 0.8rem;
}
.lock p:nth-child(2){
    padding: 0 0.8rem;
}
.lock-button{
    font-weight: 600;
    padding: 0.8rem;
    cursor: pointer;
    width: fit-content;
}
.lock-button span{
    border-radius: 0.4rem;
    display: flex;
    align-items: flex-start;
    gap: 0.4rem;
    font-size: 0.8rem;
    padding: 0.8rem 0.8rem;
    background: #02720d;
}