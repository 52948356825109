.all-applied-jobs-card-container{
    display: grid;
    grid-template-columns: repeat(6, 1fr); 
    gap: 0.5rem; 
    margin-top: 1rem;
}
.all-applied-jobs-container{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.all-applied-jobs-header{
    display: flex;
    align-items: center;
  gap: 16rem;

}