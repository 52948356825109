.filter-video-tags {
  margin: 2rem auto;
  width: 90%;
}

.current-video-container {
  width: 100%;
  height: 40vh;
  position: relative;
  border-radius: 0.4rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.thumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.4rem;
  filter: brightness(80%);
}
.watched-button,
.info-play {
  position: absolute;
}
.watched-button {
  right: 4%;
  top: 8%;
  background: #fff;
  padding: 0.8rem;
  border-radius: 0.4rem;
}
.info-play {
  color: #fff;
  left: 4%;
}
.info-play .play-button {
  background: #ff9533;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 0.8rem;
}
.allVideos-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.video-card-container {
  display: flex;
  justify-content: flex-start;
  overflow-y: scroll;
  gap: 1rem;
  margin-top: 2rem;
  position: relative;
}
.video-card-container::-webkit-scrollbar{
  display: none;
}
.leftscroll{
  display: flex;
  align-items: center;
  position: absolute;
  top: 30%;
  left:0%;
  z-index: 2;
  height: 40%;
  background: #ffffff5e;
  cursor: pointer;
}
.rightscroll{
display: flex;
  align-items: center;
  position: absolute;
  top: 30%;
  right:-2%;
  z-index: 2;
  height: 40%;
  background: #ffffff5e;
  cursor: pointer;
}
.video-card {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex: 0 0 19%;
  border-radius: 0.4rem;
  justify-content: space-between;
  position: relative;
  border: 1px solid #B0E4DD;
  gap: 1.2rem;
  cursor: pointer;
}
.playicon{
    position: absolute;
    left: 40%;
    top: 16%;
    display: none;
}
.video-card-image {
  display: flex;
  justify-content: center;
}

.video-card-image img {
  object-fit: cover;
  width: 100%;
  border-radius: 0.4rem;
}

.video-card-title p{
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.4rem;
}
.like-comment{
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}
.video-card-history{
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex: 0 0 17%;
  border-radius: 0.4rem;
  justify-content: space-between;
  position: relative;
  border: 1px solid #B0E4DD;
  gap: 1.2rem;
  cursor: pointer;
}