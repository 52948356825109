.jobs-asses-container {
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.job-assesment {
  width: 75%;
}

.go-back {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.assesment-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.job-img-and-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.job-logo {
  width: 4rem;
  height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.job-logo img {
  width: 90%;
  height: 90%;
  object-fit: cover;
}

.title-and-designation h4 {
  font-size: 1.5rem;
  color: rgba(205, 38, 38, 1);
  font-weight: bold;
  margin: 0;
}

.title-and-designation p {
  font-size: 1.1rem;
  margin: 0;
  margin-top: 0.5em;
}

.job-description {
  width: 80%;
}

.job-description p {
  text-align: center;
}

.hr-line {
  background-color: grey;
  width: 80%;
}

.instructions {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.instructions h4 {
  font-weight: bold;
}

.check-terms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.check-terms input {
  width: 30px;
  height: 20px;
  accent-color: rgba(63, 169, 89, 1);
}
.check-terms label {
  font-size: 0.8rem;
}

.take-asses-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 1.5rem;

  padding: 0.5rem 1rem;
  border-radius: 20px;
  
  align-self: center;
}

.disabled {
  background-color: grey;
  cursor: not-allowed;
}

.not-disabled {
  background-color: rgba(63, 169, 89, 1);
  color: white;
  cursor: pointer;
}
