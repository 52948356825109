.all-subject-recommended-page{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}
.subject-recommended-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
}
.all-subject-recommended-page-search-container{
    display: flex;
    align-items: center;
    justify-content: center;
}