.practice-container {
  width: 70vw;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.practice-name {
  font-size: 0.8rem;
  margin-left: 1rem;
}

.practice-chapters-and-questions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}










.practice-questions {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.practice-questions-card {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);

  background-color: white;
  border-radius: 10px;
  padding: 3rem;
  position: relative;
}

.practice-questions-card:hover{
  background-color: rgb(254, 216, 204);
}

.code-svg {
  position: absolute;
  background-color: #74c7888f;
  padding: 0.5rem;
  border-radius: 10px;
  left: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code-svg i {
  font-size: 1rem;
  color: #3fa959;
}

.question-name {
  font-size: 1.2rem;
  text-transform: capitalize;
}

.question-description {
  width: 80%;
  font-size: 0.9rem;
}
.solve-challenge-btn {
  width: 40%;
  cursor: pointer;
}

.solve-challenge-btn p {
  width: 10rem;
  background-color: #3fa959;
  padding: 0.8rem;
  border-radius: 10px;
  color: white;
  /* box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
}


.cloud-girl{
    position: absolute;
    bottom: 0;
    right: 2rem;
}

.cloud-girl img{
    width: 10rem;
}