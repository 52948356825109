.single-job-about-container{
    background: white;
    padding: 1rem;

    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}
.single-job-container {

    width: 16rem;
      /* height: 17rem; */
      border: 1px solid rgba(11, 174, 219, 1);
    box-sizing: border-box;
    gap: 1rem;
    border-radius: 10px;
    padding: 10px;
    background: rgba(234, 244, 255, 1);
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
 

  }
  
  .job-logo {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .job-logo img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .jobs-description {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .job-logo-and-company {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
  
  .jobCmpnyName {
  
    font-size: 14px;
    font-weight: 400;

    
  }
  
  .jobTitle {
    font-size: 1rem;
  }
  
  .job-tags h4 {
    border-radius: 10px;
   background-color: white;
    font-size: 0.7rem;
    padding: 3px 10px 3px 10px;
    font-family: Poppins;
    font-size: 7.65px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  border: 0.77px solid rgba(0, 169, 145, 1)
  }
  
  .job-card-btn {
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 0.8rem;
    align-items: center;
  }
  
  .apply-job-btn {
    background: rgba(4, 61, 77, 1);

    color: white;
    border-radius: 10px;
    padding: 0.5rem;
    width: 45%;
    text-align: center;
    /* height: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .save-job-btn {
    background-color: white;
    border: 2px solid #3fa959;
    border-radius: 10px;
    padding: 0.5rem;
    width: 45%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 1rem; */
  }
  .job-tags-container{
    display: flex;
    gap: 0.5rem;
    flex: auto;
  }
  .job-card-location-salary-container{
    display: flex;
    flex-direction: column;
  }
  .job-card-salary-text{
    
font-size: 14px;
font-weight: 700;
margin: 0;

  }
  .job-card-location-text{
    
font-size: 14px;
font-weight: 300;


  }