.history-video-container{
    display: flex;
    flex-direction: column;
    padding: 2rem;
}
.video-card-container-history{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    position: relative;
}
.playicon-history{
    position: absolute;
    left: 15%;
    top: 24%;
}
.video-card-image-history img{
    object-fit: cover;
    width: 100%;
    border-radius: 0.4rem;
}
.video-card-history{
    width: 40rem;
}
.video-card-title h6{
font-size: 12px;
font-weight: 300;
margin: 0;
}
.like-comment-history{
    padding: 0.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.like-comment-history span{
    font-size: 12px;
font-weight: 300;
display: flex;
align-items: center;
}
.like-comment-history button{
    background: none;
    border: 1px solid rgba(242, 20, 6, 1);
    border-radius: 1rem;
    color: rgba(242, 20, 6, 1);
    padding: 0.5rem;
    cursor: pointer;

}