.dash-subject-progress-container {
  display: flex;
  gap: 0.8rem;
}

.programs-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.program-card-container {
  /* width: 60%; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}

.subjects-container h3 {
  margin-left: 5rem;
  align-self: flex-start;
  font-size: 0.8rem;
}

.subjects-and-add-courses {
border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(176, 228, 221, 1);
  padding: 1.5rem;
}

.subjects-heading {
  font-size: 1rem;
  font-weight: bold;
}

.add-courses {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.7rem;
  color: #349367;
  border: 1px solid #349367;
  border-radius: 0.4em;
  cursor: pointer;
  background-color: white;
  padding: 0.6rem;
}

.subject-item {
  display: flex;
  border-radius: 1rem;
  width: 49%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
}

.course-name {
  padding: 1rem;
  display: flex;
    flex-direction: column;
    gap: 1rem;

}
.course-name h2 {
  width: fit-content;
  margin: 0px;

font-size: 30px;
font-weight: 500;
color: rgba(0, 0, 0, 0.5);


}
.course-title {
  width: 50%;
}



.view-all-chapters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  color: #349367;
  margin-top: 1rem;
  text-decoration: none;
}

.view-all-chapters p {
  margin: 0;
  font-size: 0.6rem;
}

.view-all-chapters i {
  font-size: 0.5rem;
}

.course-title-and-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course-title-and-actions p {
  font-size: 1.4rem;
}

.course-title-and-actions button {
  background-color: white;
  border: none;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  padding: 0.8rem;
  cursor: pointer;
}

/* .progress-bar-container {
  width: 100%;
  height: 1rem;
  background-color: #9cc9b4;
  position: absolute;
  left: 0;
  bottom: 0;
}

.progress-bar {
  width: 30%;
  height: 100%;
  background-color: #349367;
  border-radius: 0 20px 20px 0;
} */

/* background design */
.progress-circle-one {
  width: 5rem;
  height: 5rem;
  border: 10px solid #34936798;
  position: absolute;
  border-radius: 50%;
  top: -25%;
  right: 10%;
}

.progress-circle-two {
  width: 3rem;
  height: 3rem;
  background-color: #34936798;
  position: absolute;
  border-radius: 50%;
  bottom: -5%;
  left: 70%;
}

/* discover courses */

.discover-courses {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.discover-courses-container {
  background-color: #bbb5f1;
  padding: 1rem;
  border-radius: 20px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* transform: translateX(4rem); */
}

.right-arrow {
  font-size: 1rem;
  transform: translateX(-10rem);
  border-radius: 10px;
  padding: 0.8rem;
  margin-top: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.course-container {
  width: 32rem;
  /* height: 20rem; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
 padding: 1rem;
  background-color: white;
  border: 1px solid rgba(235, 227, 218, 1);
  cursor: pointer;
}
.subjects-header-course-track-container{
  width: 11rem;
  height: 3rem;
padding: 5px 10px 5px 10px;
border-radius: 50px ;
background: rgba(255, 242, 230, 1);
display: flex;
align-items: center;
justify-content: center;
justify-content: space-around;
cursor: pointer;
}
.subjects-header-search-courses-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subjects-header-course-track-circle{
  width: 40px;
height: 40px;
box-shadow: 0px 4px 4px 0px rgba(227, 218, 235, 1);
border-radius: 50%;
background: rgba(255, 149, 51, 1);


}
.progress-track-box{
  background: rgba(255, 194, 138, 1);
width: 8rem;

font-size: 13.95px;
font-weight: 700;


padding: 10px;
border-radius: 40px;
display: flex;
align-items: center;
justify-content: center;
}
.subjects-resume-track-buttton{
  background: rgba(7, 184, 24, 1);
width: 13rem;
height: 3rem;
color: white;
font-size: 20px;
border: none;
border-radius: 10px;
cursor: pointer;
}
.course-name p{
  text-align: start;

font-size: 20px;
font-weight: 400;

}
.course-name-details p{
text-align: start;
font-size: 14px;
font-weight: 300;
}
.course-certification{
  background: linear-gradient(90deg, #F6D365 0%, #FDA085 100%);
  width: 13rem;
  height: 3rem; 
  padding: 4px 15px 4px 15px;
  border-radius: 2px ;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.course-projects-details-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course-projects-container{
  display: flex;
  gap: 0.5rem;
  align-items: center;
 

}
.course-projects-container p{
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}
.course-details-container{
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(242, 236, 230, 1);
border-radius: 1rem;
padding: 1rem;
font-size: 18px;
font-weight: 400;

}
.subject-course-progress{
  background: rgba(255, 242, 230, 1);
  box-shadow: 0px 4px 10px 0px rgba(68, 9, 120, 0.1);
  /* width: 1580px; */
  /* height: 400px; */
  border-radius: 10px ;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  
}
.subject-course-progress-text-container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subject-course-progress-text-container h1{
font-size: 30px;
font-weight: 500;
}
.subject-course-progress-text-container p{

font-size: 18px;
font-weight: 300;
opacity: 80%;

}
.subject-course-progress--view-all-button{
  background: rgba(7, 184, 24, 1);
  font-size: 18px;
  font-weight: 700;
  color: white;
  padding: 12px 15px 12px 15px;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.25)  ;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}