/* EventModal.css */

.modal-container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 33%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  background-color: #f0f4f8;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-header button{
  border: none;
}
.modal-body {
  padding: 20px;
}

.form-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.input-field {
  padding: 10px;
  border: none;
  font-size: 24px;
  font-weight: 700;
  opacity: 60%;
  padding: 0;
}

.label-selector {
  display: flex;
  gap: 10px;
}

.label {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

.label.selected {
  background-color: green; /* Example background color for selected label */
}

.check-icon {
  color: white;
}

.delete-icon,
.close-icon {
  cursor: pointer;
}

.modal-footer {
  border-top: 1px solid #ccc;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
}

.save-button {
  background-color: blue;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: darkblue;
}
.task-type {
  display: flex;
  gap: 0.5rem;
}
.task-type button {
  border: none;
  background: none;
cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  opacity: 60%;
}
.button.active {
  background-color: rgb(139 187 246);
  color: rgb(8 48 235);
  border-radius: 0.5rem;
  padding: 0.3rem;
}
.calendar-event-time, .calendar-event-modal-sub-containers{
  display: flex;
  gap: 0.5rem;
}
.calendar-event-time img{
  width: 2rem;
  height: 2rem;
}

.calendar-event-sub-headings{
  font-size: 15px;
  font-weight: 700;
  opacity: 60%;
}