.disabled {
  background-color: grey;
  cursor: not-allowed;
}

.not-disabled {
  background-color: rgba(63, 169, 89, 1);
  color: white;
  cursor: pointer;
}

.questioner-btn {
  width: 5rem;
  padding: 0.5rem;
  background-color: green;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.questioner-btn:disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}

.back-btn {
  width: 3rem;
  padding: 0.5rem;
  background-color: rgb(115, 150, 202);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exit-btn{
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  transform: rotate(180deg);
}

.choice-0-0,
.choice-0-1,
.choice-0-2,
.choice-0-3,
.choice-0-4,
.choice-0-5 {
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  padding: .5rem;
  padding-top: 1rem
}

.choice-1-0,
.choice-1-1 {
  width: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  padding: .5rem;
  padding-top: 1rem
}

.choice-2-0,
.choice-2-1 {
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  padding: .5rem;
  padding-top: 1rem
}
.choice-3-0,
.choice-3-1,
.choice-3-2 {
  width: 5rem;
  height: 2rem;
  display: flex;
  justify-content: ceter;
  align-items: center;
  padding: .5rem;
}
.choice-3-0 img,
.choice-3-1 img,
.choice-3-2 img {
  width: 1.3rem;
  height: 1.3rem;

}
