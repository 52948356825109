.library-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.library-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  padding: 1rem;
}
.library-container::-webkit-scrollbar{
  display: none;
}

.library-container .content-video {
  padding: 0.8rem;
  width: 70%;
}

.search-switch-container {
  display: flex;
  margin: 0 auto;
}
.left-library-container::-webkit-scrollbar{
  display: none;
}
.search-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-switch-container .input {
  width: 30%;
}
.search-switch-container input {
  padding: 0.8rem;
  width: 100%;
  border-radius: 0.4em;
  outline: none;
  border: 2px solid #c5b3d5;
}
.search-switch-container input::placeholder {
  opacity: 0.5;
}


.search-switch-container .switch-button {
  margin-left: 3rem;
  background: #fff2e6;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  padding: 0.4rem .8rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: flex-direction 0.5s ease-in-out;
}
.search-switch-container .switch-button.active {
  flex-direction: row-reverse;
}

.search-switch-container .switch-button .circle {
  background: #ff9533;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
  box-shadow: 0px 4px 4px 0px #e3daeb;
}
.search-switch-container .switch-button.active .circle {
  transform: translateX(10%);
}

.search-switch-container .switch-button span {
  font-size: 0.8rem;
}
