.sample-certficate-container{
    background: rgba(255, 255, 255, 1);

 
    border-radius: 10px ;  
   
}
.sample-certificate-text-container{
display: flex;
flex-direction: column;
align-items: start;
gap: 1rem;
padding: 2rem;
}
.sample-certificate-text-container h1{
    font-size: 30px;
    font-weight: 500; 
    margin: 0;
}
.sample-certificate-text-container p{
font-size: 16px;
font-weight: 400;
color: rgba(0, 0, 0, 0.6);

text-align: start;
}
.sample-certficate-container{
    display: flex;

}
.sample-certificate-generate-button{
    background: rgba(7, 184, 24, 1);
    

padding: 1rem;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 1366px)and (max-height: 768px) {
    .sample-certificate-image-container img{
        display: none;
    }

  }