.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .popup-content h2 {
    margin-bottom: 20px;
  }
  
  .popup-content label {
    display: block;
font-size: 20px;
font-weight: 400;

  }
  
  .popup-content input[type="text"] {
    width: 70%;
    height: 2rem;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup-content button {
    background-color: rgba(7, 184, 24, 1);
    color: white;
    padding: 14px 32px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
  }
  
  .popup-content button:hover {
    background-color: #0056b3;
  }
  
  .popup-content .delivery-status p {
    margin-top: 10px;
    font-weight: bold;
  }
  .location-header{
    border-bottom: 1px solid rgba(197, 179, 213, 1);
  }
  .location-header h2{
    margin: 0;
font-size: 20px;
font-weight: 400;

  }