.dash-header-container {
  
}

.dash-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  height: 8vh;
}

.user-img-and-name {
  display: flex;
  justify-content: space-around;
  gap: 0.4rem;
  padding: 0.8rem;
  align-items: center;
  cursor: pointer;
}

.ring {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 10px solid #8ce2a2be;
  border-radius: 50%;
  top: -5rem;
  left: -2rem;
}

.header-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #9cc9b4;
  top: -1.5rem;
  right: 40%;
}

/* Navbar styling */
.dash-nav-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex: 0 0 14%;
  justify-content: space-between;
  overflow-y: scroll;
  height: 100vh;
}
.dash-nav-container::-webkit-scrollbar{
  display: none;
}
.logo-menu{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.all-dash-nav-link {
  display: flex;
  flex-direction: column;
  /* gap: 0.8rem; */
}

.dash-navs {
  position: relative;
}

.dash-navs a {
  padding: 1rem;
}


.dash-navs .bar{
  position: absolute;
  width: 2%;
  background: #3fa959;
  height: 100%;
  right: 0;
  top: 0;
}

.active-link {
  background: #D9F2EF;
}

.dash-ad {
  width: 90%;
  margin: 2rem auto 0.4rem auto;
  border-radius: 0.4em;
  background-color: #3fa959;
  /* position: absolute; */
  /* bottom: 0; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.4em;
}

.ad-dash-in {
  width: 80%;
  color: white;
  /* padding-left: 1rem; */
}

.ad-dash-in h4 {
  font-size: 1rem;
  font-weight: 500;
}

.dash-join {
  background-color: white;
  color: black;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  /* font-weight: bold; */
  padding: 0.6rem;
  cursor: pointer;
}

.dash-ad-img img {
  max-width: 5em;
  margin: 2rem 0;
}

.byte-logo {
  width: 80%;
  margin: 1rem auto;
  margin-top: 2rem;
}
.byte-logo:hover {
  cursor: pointer;
}

.byte-logo img {
  width: inherit;
}

/* profile box */
.user-profile-box {
  position: absolute;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 3rem;
  right: 2%;
  z-index: 1;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.profile-navs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.6rem;
  margin: 1rem 0;
}

.profile-navs p {
  margin: 0;
  cursor: pointer;
}

.user-navs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 0.7rem;
}

.user-navs h4 {
  margin: 0;
  text-align: start;
}

.user-img-and-name {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-img-and-name h2 {
  width: 80%;
  font-size: 1.2rem;
  margin: 0;
}

.heart-book-img img {
  width: 10rem;
  margin-bottom: 2rem;
}

.user-profile-image {
  width: 2rem;
  height: 2rem;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: auto;
}

.user-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
