.know-you-container{
    display: flex;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    gap: 1rem;
}

.know-you-meeting-details-left{
    width: 42rem;
height: 10rem;
padding: 19px 30px 19px 30px;

border-radius: 10px ;
display: flex;
border: 1px solid rgba(236, 230, 242, 1);
align-items: center;
justify-content: space-between;
}
.know-you-meeting-booking-details{
    display: flex;
    flex-direction:column ;
}
.know-you-meeting-booking-details-container{
    display: flex;
    gap: 0.5rem;
}
.know-you-meeting-booking-details-sub-text{
font-size: 14px;
font-weight: 400;
color: rgba(68, 9, 120, 1);
cursor: pointer;
}
.know-you-meeting-booking-details-edit-button{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.know-you-meeting-details-right{
border-radius: 20px;
border: 1px solid rgba(236, 230, 242, 1);
box-shadow: 0px 1px 2px 0px rgba(68, 9, 120, 0.15);
padding: 2rem;
display: flex;
    flex-direction: column;
    gap: 1rem;

}
.let-us-know-you-inputs{
font-size: 20px;
font-weight: 400;
}
.know-you-meeting-details-right-input-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
}


.know-you-meeting-details-right-input-container input{
    width: 23rem;
height: 46px;
padding: 10.61px 37.93px 11.39px 17.07px;
border-radius: 9.07px;
border: 0.72px solid rgba(0, 0, 0, 1);

font-size: 16px;
font-weight: 400;


}
.like-section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
height: 209px;

padding: 15px 25px 15px 25px;
border-radius: 20px;
border: 1px solid rgba(197, 179, 213, 1);
margin-top: 2rem;
}

.let-us-know-you-heading{
font-size: 44px;
font-weight: 500;
margin: 0
;

}

.like-section-heading-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.details-container{
    display: flex;
    flex-direction: column;
gap: 1rem;
}
.details-sub-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.add-button{
    
height: 40px;
border: none;
padding: 8px 16px 8px 16px;
border-radius: 20px ;
opacity: 0px;
background: rgba(7, 184, 24, 1);
color: white;
font-size: 16px;
font-weight: 700;
}
.details-sub-text-container{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: start;

font-size: 14px;
font-weight: 700;


}
.know-you-meeting-booking-details-heading-text{

font-size: 22px;
font-weight: 600;


}
.details-sub-text{

font-size: 14px;
font-weight: 300;


}
.details-sub-container:nth-child(1){
    border-bottom: 1px solid rgba(213, 197, 179, 1);
    padding-bottom: 0.5rem;
}
.confirm-pay-section{
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.confirm-pay-section-button{
    background: rgba(7, 184, 24, 1);
    width: 46.5rem;
    height: 71px;
   border: none;
    padding: 20px 35px 19px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 2rem;
font-size: 22px;
font-weight: 600;
color: rgba(255, 255, 255, 1);

}
.hide-like-section{
    border: none;
    background: none;
font-size: 18px;
font-weight: 700;
color: rgba(0, 0, 0, 0.5);

}
.like-section-heading-container h2{

font-size: 22px;
font-weight: 600;


}
@media (max-width: 1366px)and (max-height: 768px) {
    .know-you-meeting-details-right {
        height: 47.9rem;
        width: 34rem;
    }
    .know-you-meeting-details-right-input-container input {
        width: 12rem;
       
    }
    .confirm-pay-section-button {
       
        width: 34rem;
      
    }
}