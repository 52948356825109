.my-dashboard-body {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background: snow;
}

.enrolled-progress {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.enrolled-progress h1 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(15, 23, 42, 1);
}
.progress-course-details h4 {
  font-size: 20px;
  font-weight: 700;
}
.my-dashboard-body-left-part {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.dashboard-booked-session-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.dashboard-booked-session-container-heading {
  font-size: 18px;
  font-weight: 700;
}
.dashboard-booked-session-container-mentorship-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
.my-dashboard-header {
  padding-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 194, 138, 1);
  border-radius: 1rem;
}
.my-dashboard-header-text-container h1 {
  font-size: 30px;
  font-weight: 500;
}
.my-dashboard-header-text-container p {
  font-size: 18px;
  font-weight: 700;
}

.my-dashboard-header-image-container-image2 {
  /* margin-top: -11rem; */
}

.my-dashboard-header-image-container {
 width: 18%;
 margin-right: 5rem;
}
.my-dashboard-header-image-container img{
  width: 100%;
  object-fit: contain;
  
 }


.my-dashboard {
  width: 85vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
}

.dashboard-intro {
  width: 80%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  gap: 1rem;
}
.dashboard-intro h2 {
  font-size: 2rem;
  color: #3fa959;
  font-weight: bold;
}

.dashboard-intro p {
  text-align: center;
}

.start-learning {
  border-radius: 20px;
  background-color: #3f3d56;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.recommendations {
  display: flex;
  gap: 1rem;
  /* height: 100%; */
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.recommendation {
  display: flex;
  width: 15rem;
  height: 3rem;
  background-color: #34936733;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 1rem; */
  padding: 0.5rem;
  cursor: pointer;
}

.recommendation p {
  width: 80%;
  font-size: 0.6rem;
}

.recommendation img {
  max-width: 1.5vw;
}

.input-and-send {
  width: 55vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 2rem; */
  align-self: center;
}

.searching {
  width: 50vw;
  height: 2.5rem;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #62b38d;
}

.searching input {
  width: 95%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  background-color: rgba(52, 147, 103, 0.17);
  /* color: white; */
}

::placeholder {
  color: black;
}

.searching img {
  max-width: 1.5rem;
  height: 100%;
}

.send {
  background-color: #349367;
  border-radius: 10px;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send img {
  color: white;
  max-width: 1.3rem;
  cursor: pointer;
}

/* if the user enrolled data is shown then the dasah board design */

.progress-card-container {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  overflow-x: scroll;
}
.progress-card-container::-webkit-scrollbar {
  display: none;
}
.prog-course-name-percent p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 59, 51, 1);
}
.progress-card {
  gap: 2rem;
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 21.5rem;
  background: rgba(255, 255, 255, 1);

}

.prog-course-name-percent {
  gap: 1rem;
  display: flex;
  align-items: center;
}

.progress-peercent {
  border: 1px solid #3fa959;
  background-color: #d6e9e1;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
}

.progress-course-img {
  width: 50%;
}

.progress-course-img img {
  width: 60%;
  height: 100%;
  object-fit: cover;
}

.my-dashobard-body-byte-score {
  gap: 1rem;
  border-radius: 10.79px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3.6px 28.77px 0px rgba(170, 170, 170, 0.03);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 25%;
}
.my-dashboard-byte-score-heading {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.my-dashboard-byte-score-heading h1 {
  font-size: 18px;
  font-weight: 700;
}

.my-dashboard-byte-score-results h1 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(255, 152, 0, 1);
}
.my-dashboard-byte-score-results p {
  font-size: 16px;
  font-weight: 400;
}
.my-dashboard-byte-score-results-tips h1 {
  font-size: 16px;
  font-weight: 700;
}
.my-dashboard-byte-score-results-tips p {
  font-size: 16px;
  font-weight: 400;
}
.my-dashboard-about-byte-score h1 {
  font-size: 16px;
  font-weight: 700;
}
.my-dashboard-about-byte-score p {
  font-size: 14px;
  font-weight: 400;
width: 90%;
}
.my-dashboard-about-byte-score{
  padding: 1rem;
  background: rgba(255, 242, 230, 1);
  border-radius: 1rem;
}
@media (max-width: 1366px)and (max-height: 768px) {
.progress-card-container{
  flex-direction: column;
}
}