.user-profile-nav {
  width: 100%;
  height: 3rem;
  background-color: white;
  display: flex;
  gap: 2rem;
  padding: 0.5rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: flex-start;
  align-items: flex-end;
  /* box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.349); */
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding-left: 0rem 2rem;
  align-items:flex-end
}
.user-profile-nav p{
  font-size: medium;
}

.user-profile-and-courses {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.user-profile,
.user-courses {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-left: .5rem;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;

}

.back-to-dashboard {
  margin: 0;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  border-right: 1px solid;
  padding-right: 1rem;
  align-items: flex-end;
}

.user-profile::after,
.user-courses::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 0;
  height: 5px;
  background-color: #349367;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.user-profile.active::after,
.user-courses.active::after {
  width: 100%;
}

