.subscription-success-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;


    .contain {
        border: 1px solid #00000030;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 50vh;
        border-radius: 0.4rem;

        img {
            width: 40%;
        }

        .continue {
            background: #02720d;
            color: white;
            // border: 1px solid #FFA754;
            box-shadow: 0px 1px 2px 0px #44097826;
            font-weight: 600;
            padding: 0.8rem;

        }

        .continue:hover {
            cursor: pointer;
        }

        .delivery-information {
            background: #FFF2E6;
            color: #440978;
            width: 100%;
            text-align: center;
            padding: 0.4rem;
            border-radius: 10px;
        }
    }
}