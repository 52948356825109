form {
  /* color: #db2727; */
  display: flex;
  padding: 2px;
  /* border: 1px solid currentColor; */
  border-radius: 5px;
  /* width: 20em; */
}

/* input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  width: 100%;
} */

/* input[type="search"]::placeholder {
  color: #bbb;
} */

/* button[type="submit"] {
      text-indent: -999px;
      overflow: hidden;
      width: 40px;
      padding: 0;
      margin: 0;
      border: 1px solid transparent;
      border-radius: inherit;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
      cursor: pointer;
      opacity: 0.7;
    }
    
    button[type="submit"]:hover {
      opacity: 1;
    } */

/* button[type="submit"]:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
} */

.search-btn {
  width: 40vw;
  height: 60px;
  border-radius: 20px;
}

.search-btn button{
  cursor: pointer;
}

input[type="search"] {
  height: 100%;
  font-size: 1.25rem;
  padding: 10px;
}

button[type="submit"] {
  /* width: 20vw; */
  border-radius: 20px;
  border: none;
  background-color: black;
  color: white;
}

/* view All courses button */
/* .all-courses-btn{
  border: 1px solid;
  width: 10rem;
  height: 2.5rem;
  border-radius: 10px;
  background-color: black;
  color: white;
} */

.all-courses-btn button {
  margin: 2rem 0;
  border: none;
  width: 9rem;
  height: 3rem;
  border-radius: 10px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.all-blogs-btn {
  margin: 2rem 0;
  border: none;
  width: 10rem;
  height: 2.7rem;
  border-radius: 10px;
  background-color: black;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* text-align: center; */
}

.green button {
  background: rgba(7, 184, 24, 1);
  color: white;
  margin: 2rem 0;
  border: none;
  width: 8rem;
  height: 2.8rem;
  border-radius: 10px;
  cursor: pointer;
font-size: 16px;
font-weight: 700;


}

.clickable-btn {
  width: 7em;
  height: 3em;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  color: white;
}


.chapters {
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.2);

  /* background-color: #56bd8d7e; */
  border-radius: 20px;
  padding: 1rem 2rem 7rem 0;
  padding-left: 0.5rem;
}

.chapter-heading {
  font-size: 0.6rem;
  font-weight: bold;
  margin-left: 1rem;
}

.chapter-name {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.chapter-number {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.chapter-number p {
  cursor: pointer;
  padding: 0.5rem;
}

.chapter-number p:hover {
  background-color: #56bd8d7e;
  border-radius: 10px;
}

.chapter-number.selected {
  height: 100%;
  background-color: #56bd8d7e;
  border-radius: 10px;
  font-weight: bold;
}



/* responsive layout */
@media (max-width: 850px) {
  .search-btn {
    width: 90vw;
    height: 60px;
    border-radius: 20px;
  }
  
  button[type="submit"] {
    width: 50vw;
    border-radius: 20px;
    border: none;
    background-color: black;
    color: white;
  }

  .all-courses-btn button{
    height: 3rem;
  }
  

  .all-blogs-btn{
    display: none;
  }

  .clickable-btn {
    width: 10em;
    height: 4em;
    border-radius: 10px;
    border: none;
    font-size: 12px;
    color: white;
  }
  
  .green button {
    background-color: #3fa959;
    color: white;
    margin: 2rem 0;
    border: none;
    width: 6rem;
    height: 2.8rem;
    border-radius: 10px;
  }

}