.quiz-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.quiz-container {
  .bac-assessment-header {
    background: #b0e4dd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;
    margin-bottom: 2rem;

    .image-title {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      gap: 0.8rem;
      img {
        width: 12%;
      }
    }
  }

  .question-length-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 1rem;
    margin: 2rem auto;
    width: 50%;

    .quiz-questions-container {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      margin-top: 1rem;
      padding: 1rem 0;

      ul {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 0.8rem;
        li {
          padding: 0.8rem;
          background: #34936733;
          border-radius: 0.4rem;
          cursor: pointer;

          .option {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            span {
              background: #fff;
              padding: 0.2rem 0.4rem;
              border-radius: 0.2rem;
            }
          }
          &.selected {
            background: #02720d;
            color: white;

            .option span {
              background: white;
              color: #02720d;
            }
          }

          &:hover {
            background: #02720d;
            color: white;

            .option span {
              background: white;
              color: #02720d;
            }
          }
        }
      }
    }

    .quiz-length {
      background: #fefacd;
      width: fit-content;
      padding: 0.4rem 0.8rem;
      margin: 0 auto;
      font-weight: 700;
      color: #00000071;
    }
  }

  .next-button {
    display: flex;
    justify-content: center;
    span {
      background: #02720d;
      color: #fff;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0.2rem 1.5rem;
      border-radius: 0.2rem;
      cursor: pointer;
      font-size: 1.4rem;
    }
  }
}
.submit-confirmation {
  position: fixed;
  height: 14rem;
  border-radius: 1rem;
  width: 40rem;
  top: 14%;
  left: 56%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1rem;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 1rem;
  }

  button {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    background: #02720d;
    color: white;
    border-radius: 0.2rem;
    height: 3rem;
    width: 8rem;
    font-size: 1rem;
    font-weight: 700;
    &:hover {
      background: #025a0a;
    }

    &:nth-child(2) {
      background: #d9534f;

      &:hover {
        background: #c9302c;
      }
    }
  }
}
