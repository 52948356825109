.subscription-container *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.subscription-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
    width: 96%;
    margin: 4rem auto;
}
.subcription-heading p{
    text-align: center;
}
.plans-container .card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    box-shadow: 0px 2.67px 13.36px 0px #00000026;
    border-radius: 0.4rem;
    padding: 0 1rem 1rem 1rem;
}
.plans-container .card-container .content .image{
    width: 40%;
    margin-top: 2rem;
}
.plans-container .card-container .content .image img{
    width: 100%;
}

.content .features{
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    font-weight: 400;
}
.content .features .feature{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.content .features .feature .mark{
    background: #1DFDAC;
    border-radius: 50%;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-container hr{
    width: 100%;
}
.card-container .plan-pricing{
    margin: 0.8rem 0;
}
.card-container .plan-pricing h2{
    font-size: 2rem;
}
.card-container .plan-pricing h2 span{
    color: #a1a0a0;
    font-size: 1rem;
}
.card-container .plan-btn{
    background: #02720d;
    padding: 0.8rem 1rem;
    border-radius: 2rem;
    color: #fff;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-container .expired-plan-btn{
    background: #959896;
    padding: 0.8rem 1rem;
    border-radius: 2rem;
    color: #fff;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plans-container{
    display: flex;
    justify-content: center;
    gap: 0.8rem;
}
.card-container .popular-tag{
    background: #FFC28A;
    height: 2rem;
    width: 40%;
    margin: 0 auto;
    font-weight: 600;
    border-radius: 0px 0px 0.6rem 0.6rem;
    text-align: center;
}

.subscription-container .trial-plan{
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    padding: 0.8rem;
}