.contact-container *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.contact-container{
    display: flex;
    gap: 0.8rem;
    padding: 1rem;
}
.contact-detail-section{
    flex: 0 0 70%;
}
.contact-detail-section .accordion{
    margin: 0 auto;
    padding: 0.8rem;
}
.accordion .contact-title{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-weight: 600;
    padding: 0.8rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    justify-content: space-between;
}
.contact-detail-section .form-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    width: 100%;
}
.contact-detail-section .form-container .column-flex{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.contact-detail-section .form-container .column-flex label{
    font-size: 0.8rem;
}
.contact-detail-section .form-container .column-flex input{
    padding: 0.6rem;
    outline: none;
    border-radius: 0.2rem;
    border: 0.94px solid #00000040;
}
.contact-detail-section .form-container .column-flex textarea{
    padding: 0.8rem;
    outline: none;
    font-family: inherit;
    resize: none;
    border-radius: 0.2rem;
    border: 0.94px solid #00000040;
}
.contact-detail-section .form-container .column-flex input::placeholder,textarea::placeholder{
    opacity: 0.5;
    font-weight: 500;
}
.contact-detail-section .form-container .delivery-date{
    color: #bc610c;
    font-size: 0.8rem;
    padding: 0;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}
.accordion .button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.accordion .button span{
    background: #02720d;
    color: #fff;
    width: fit-content;
    border-radius: 0.2rem;
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.contact-container .pricing{
    padding: 0.8rem;
}
.contact-container .pricing .checkout-button{
    margin: 1rem auto;
    padding: 0.4rem;
}