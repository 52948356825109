.search-and-popular {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.select-shortcut .css-a1g57g-MuiButtonBase-root-MuiChip-root {
  color: black;
  border: none;
  font-size: 18px;
  font-weight: 400;
}
.select-shortcut .css-a1g57g-MuiButtonBase-root-MuiChip-root:hover{

color: white;
}
.select-shortcut:hover{
    background: rgba(255, 149, 51, 1);
}
.select-shortcut {
  background: rgba(242, 236, 230, 1);
  border-radius: 10px;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
