.subject-course-container{
   width: 20rem;
   height: 28rem;
padding-bottom: 2rem;
border-radius: 10.79px ;
border: 1px solid rgba(176, 228, 221, 1)

}
.course-name-details{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.course-name-details img{
    width: 2rem;
    height: 2rem;
}

.course-certification h1{
color: rgba(0, 35, 51, 1);
font-size: 14px;
font-weight: 400;


}