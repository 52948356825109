.assesment-container{
    margin: 1rem 0;

    .assessment-card-container{
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .assessment-card{
            display: flex;
            flex-direction: column;
            padding: 0.8rem;
            border: 1px solid #D5C5B3;
            background: linear-gradient(45deg, #FFFFFF 2.67%, #FFFFFF 51.62%, #E6F6F4 97.33%);
            border-radius: 0.4rem;
            gap: 2rem;

            .head{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6rem;

                .expired-date{
                    background: #02720d;
                    color: #fff;
                    padding: 0.4rem 0.8rem;
                    border-radius: 2rem;
                    font-size: 0.8rem;
                }
                .type{
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                }
            }
            .body{
                display: flex;
                flex-direction: column;
                gap: 0.4rem;
            }
            .footer{
                background: #02720d;
                padding: 0.4rem;
                border-radius: 0.4rem;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;
                cursor: pointer;
            }
        }
    }
}