
.meeting-details-right-container{
    /* max-width: 54rem; */
    padding-left: 3rem;
    padding-top: 3rem;
    padding-right: 2rem;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(236, 230, 242, 1);
    padding-bottom: 4rem;
    box-shadow: 0px 1px 2px 0px rgba(68, 9, 120, 0.15);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.slot-booking-date-container-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.slot-booking-date-container, .slot-booking-container, .slot-booking-time-zone-body{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.date-card{
    width: 5rem;
    height: 6.25rem;
padding: 21px 21px 25px 21px;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
border: 1px solid rgba(68, 9, 120, 1);
cursor: pointer;
}
.date-cards{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.time-cards{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1em;
    cursor: pointer;
}
.time-card{
    width: 118px;
height: 50px;
border-radius: 10px;
border: 1px solid rgba(68, 9, 120, 1);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}
.slot-booking-heading{
font-size: 44px;
font-weight: 500;
margin: 0;
}
.slot-booking-select-date-heading,.slot-booking-pick-a-time-heading, .slot-booking-time-zoner-heading{
    
font-size: 30px;
font-weight: 500;


}
.slot-booking-date-card-day{
    font-size: 14px;
    font-weight: 400;
    
}
.slot-booking-date-card-date{
font-size: 18px;
font-weight: 700;


}
.slot-booking-time{
font-size: 18px;
font-weight: 700;


}
.like-section-expanded {
    height: 209px;
  }
  
  .like-section-collapsed {
    height: 100px;
  }
  
  .meeting-calendar{
    position: fixed;
    top: 21rem;
    left: 36rem;
    /* width: 50%;
    height: 50%; */
    border-radius: 1rem;
    background: white; 
    cursor: pointer; 
  }


@media (min-width: 1920px) and (min-height: 1080px) {
    .meeting-details-right-container{
        width: 48rem;
    }
}

@media (max-width: 1366px)and (max-height: 768px) {
    .meeting-details-right-container {
        width: 33rem;
    
    }
    .date-card {
        width: 30px;
        height: 45px;
       
    }
    .time-cards {
        grid-template-columns: repeat(4, 1fr);

    }
  }