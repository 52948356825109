.blog-details-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
}
.blog-data-title h1 {
  font-size: 44px;
  font-weight: 500;
}
.dash-blog-detail p{
opacity: 80%;
font-size: 14px;
font-weight: 400;


}
.duration-and-action p {
  font-size: 20px;
  font-weight: 400;
}
.blog-title-image-card {
  display: flex;
  border: 1px solid;
  background-color: #f4f7f2;
  width: 100%;
  /* margin: 0 10em; */
  border-radius: 20px;
  height: 300px;
  margin-top: 2rem;
  overflow: hidden;
}
.blog-title-card {
  flex: 1 1;
  margin: 0;
  border-radius: 20px;
  width: 55%;
  background-color: #f4f7f2;
  font-weight: 900;
  font-size: 16px;
  color: darkblue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.blog-title-card h1 {
  margin: 0.5em;
}
.blog-title-card > img {
  width: 20%;

  margin: 0.5em;
  /* margin-left: 2em; */
}

.blog-img {
  margin: 0 0;
  padding: 0;
  flex: 1 1;
  width: 40%;
  height: 19rem;
  /* border-radius: 20px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-img img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.blog-data-title {
  font-size: 0.7rem;
}

.duration-and-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-comments-likes p {
  font-size: 20px;
  font-weight: 400;
}
.blog-data-para p {
  font-size: 18px;
  font-weight: 400;
}
.duration-and-action i {
  cursor: pointer;
}

.like-and-share {
  width: 5%;
  display: flex;
  justify-content: space-between;
}

.blog-data-para {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* overflow: hidden; */
  word-break: break-all;
}
.blog-data-para p {
  text-align: start;
}

.blog-data-para img {
  width: 100%;
}

.blog-data-para pre {
  width: 95%;
  background-color: rgba(61, 60, 60, 1);
  color: white;
  padding: 2em;
  border-radius: 20px;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  /* color: #666; */
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.horizontal-line {
  height: 1px;
  background-color: grey;
}

.socials-and-comments-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.socials {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.2rem;
}

.socials a {
  color: white;
  width: 20px;
  height: 20px;
  border: 1px solid;
  background-color: #a6a6a6;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
}
.socials i {
  color: white;
  width: 20px;
  height: 20px;
  border: 1px solid;
  background-color: #a6a6a6;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-comments-likes i {
  cursor: pointer;
}

.comment-heading {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.comments-and-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

.write-comment {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.write-comment textarea {
  height: 20vh;
  border-radius: 20px;
  resize: none;
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1rem;
}

.add-comment {
  width: 20%;
  align-self: end;
  border-radius: 20px;
  background-color: #3fa959;
  color: white;
  border: none;
  height: 3rem;
  cursor: pointer;
}

.comment-section {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 3px 3px 10px grey;
  margin-top: 2rem;
  padding: 1em;
  background-color: white;
  gap: 1rem;
}

.comment-hr-line {
  margin-top: 1rem;
  align-self: center;
}

.name-and-time {
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
}

.name-and-time h6 {
  font-size: 1rem;
  margin: 0;
}

.name-and-time p {
  color: #a6a6a6;
}

.comment-para-data {
  font-size: 0.9rem;
}

.red {
  color: red;
}

/* responsive layout */

@media (max-width: 850px) {
  .blog-data-para {
    font-size: 0.8rem;
  }
  .blog-title-image-card {
    display: flex;
    border: 1px solid;
    background-color: #f4f7f2;
    width: 70%;
    margin: 0 10em;
    border-radius: 20px;
    height: 300px;
  }
  .blog-title-card {
    flex: 1 1;
    margin: 0;
    border-radius: 20px;
    padding: 1em;
    width: 55%;
    background-color: #f4f7f2;
    font-weight: 900;
    font-size: 16px;
    color: darkblue;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    display: none;
  }

  .blog-img {
    margin: 0 0;
    padding: 0;
    width: 100%;
    height: 100%;
    /* width: 60vw; */
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blog-img img {
    max-width: 100%;
    /* height: 100vh; */
    /* background-repeat: repeat; */
    object-fit: cover;
  }

  .comment-section {
    margin-top: 3rem;
  }
  .comment-heading {
    width: 70vw;
  }

  .add-comment {
    width: 40%;
    height: 2.6rem;
    border-radius: 10px;
  }

  .like-and-share {
    width: 15%;
  }

  .name-and-time {
    flex-direction: column;
    width: 70vw;
    align-items: flex-start;
  }

  .write-comment textarea {
    height: 25vh;
    font-size: 0.8rem;
  }

  .socials-and-comments-data {
    flex-direction: column;
    font-size: 0.8rem;
    /* align-self: start; */
  }

  .socials {
    width: 50%;
    align-self: flex-start;
  }

  .view-comments-likes {
    align-self: flex-start;
  }
}
