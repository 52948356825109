.question-navigator {
  display: flex;
  margin-bottom: 20px;
  margin-left: 1rem;
  
    .question-box {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      margin: 0 5px;
      cursor: pointer;
     
  
      &.active {
        background-color: #4caf50;
        color: white;
      }
  
      &.attempted {
        background-color: #f1c40f;
        color: white;
      }
    }
  }
  