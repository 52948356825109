.career-track-mentors{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.career-track-mentors-card{
    display: flex;
    gap: 1rem;
} 

.career-track-mentors-card img{
    border-radius: 10px;
}
.career-track-mentors-details {
    display: flex;
    flex-direction: column;
}
.career-track-mentors-details h1{
    font-family: Poppins;
    font-size: 18.68px;
    font-weight: 700;
    margin: 0;
}
.career-track-mentors-details p{
    font-family: Poppins;
    font-size: 16.01px;
    font-weight: 400;
    color: rgba(0, 59, 161, 0.5);
    
}
.career-track-mentors-details h2{
    font-family: Poppins;
    font-size: 16.01px;
    font-weight: 400;
    margin: 0;
    
}
 .career-track-mentors-card-container{
    display: flex;
    gap: 15rem;
}
.career-track-mentors-heading{
    margin: 0;
  
font-size: 30px;
font-weight: 500;

}

@media (max-width: 1366px)and (max-height: 768px) {
    .career-track-mentors-card-container {
       
        gap: 3rem;
    }
  }