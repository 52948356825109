.cart-popup-container{
    position: absolute;
    top: 80%;
    right: 2%;
    background: #fff;
    border: 1px solid #00000040;
    border-radius: 0.4rem;
    padding: 1rem;
    text-align: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 30%;
}
.cart-popup-container p{
    width: 60%;
    margin: 1rem auto;
    text-align: center;
    font-size: 0.8rem;
}
.cart-popup-container .button{
    background: #02720d;
    color: #fff;
    border-radius: 0.4rem;
    padding: 0.4rem;
    width: 40%;
    margin: 0 auto;
    font-size: 0.8rem;
    cursor: pointer;
}
.cart-popup-container .close{
    position: absolute;
    top: 4%;
    right: 2%;
    cursor: pointer;
    color: #00000070;
}
.items-container{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin: 1rem 0;
}
.items-container li{
    display: flex;
    align-items: center;
}
.items-container li img{
    width: 8%;
}
.items-container .item-detail{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 0.8rem;
    flex: 0 0 90%;
}
.items-container .item-detail .name-quantity{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.items-container .price{
    font-weight: 500;
    color: #FF7B00;
}
.cart-items .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    color: #784409;
    font-weight: 700;
}