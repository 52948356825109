.questionpaper-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.questionpaper-container {
  .search-content-section {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background: #c5b3d5;
    h1 {
      color: #fff;
    }
    .input-filter {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      width: 56%;

      .input-field {
        padding: 0;
        border: 1px solid #ccc;
        border-radius: 0.4rem;
      }

      .filter {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        cursor: pointer;
        background: #fff;
        color: #00000090;
        padding: 0.6rem 0.8rem;
        border-radius: 0.4rem;
      }
      input {
        width: 100%;
        padding: 0.8rem;
        border-radius: 0.4em;
        border: 2px solid #c5b3d5;
        text-indent: 1.4rem;

        &::placeholder {
          color: black;
          opacity: 0.5;
          text-indent: 1.4rem;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .popular-searches {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      color: #fff;

      span {
        font-size: 1rem;
      }
      div {
        border: 1px solid #fff;
        width: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 0.8rem;
        border-radius: 0.8em;
        font-size: 1rem;
        font-weight: 900;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .exam-card-container{
    display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      width: 96%;
      margin: 1rem auto;
  }
 
}
