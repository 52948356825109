.heading-assignments-container{
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    width: 75%;
    margin-top: 3rem;

}

.assignments-heading{
    
    margin-top: 2rem;
}

.heading h2{
    padding-bottom: 0.8em;
}





.assignments-container{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 2em;
    padding-left: 3em;
}


.assignment-card{
    width: 20rem;
    height: 7rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 1em;
    /* box-shadow: 4px 4px 5px rgba(0, 0, 0, .2); */
    padding: .9rem 0;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.assignment-name{
    font-weight: bold;
    font-size: 1rem;

}

.take-assigment{
    background-color: #349367;
    border-radius: 5px;
    padding: .8rem .5rem;
    color: white;
    width: 50%;
    cursor: pointer;
    text-align: center;
}

.take-assigment p{
    font-size: 1rem;
}

.line-lighter{
    position: absolute;
    width: 2%;
    height: 100%;
    background-color: #102C42;
    top: 0;
    left: 0;
}