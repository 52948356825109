.progress-bar-container {
  width: 100%;
  height: 0.5rem;
  background-color: rgb(222, 222, 222);
  border-radius: 0 0.4rem 0 0;

  /* position: absolute; */
  left: 0;
  bottom: 0;
  overflow: hidden;
}
