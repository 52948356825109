.confirm-popup-input-container {
  height: 3rem;
  padding: 0.85px;
  border-radius: 4.06px;
  border: 1.35px;
  background: rgba(255, 255, 255, 1);
  border: 1.35px solid rgba(221, 221, 221, 1);
  padding-left: 1rem;
  width: 13rem;
  font-size: 18.94px;
  font-weight: 400;
  color: rgba(138, 138, 138, 1);
}
.confirm-popup-input-container-mobile-number {
  width: 24rem;
}
.confirm-popup-student-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.confirm-popup-student-details h1 {
  font-size: 44px;
  font-weight: 500;
  margin: 0;
}
.confirm-popup-student-details h2 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}
.confirm-popup-student-details p {
  font-size: 20px;
  font-weight: 400;
  border: 1px solid rgba(24, 3, 42, 1);
  border-radius: 10px;
  padding: 0.5rem;
}
.confirm-popup-contact-form-body {
  width: 487px;

  border-radius: 8.12px;
  border: 1.35px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.confirm-popup-contact-form h1 {
  font-size: 18.94px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
.confirm-popup-batch-details {
  width: 432.89px;
  height: 48.7px;

  border-radius: 4.06px;
  border: 1.35px solid rgba(221, 221, 221, 1);
}
.confirm-popup-selected-option {
  align-items: center;
  justify-content: space-between;
  width: 28.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  border-radius: 4.06px;
  padding: 1rem;
  border: 1.35px solid rgba(221, 221, 221, 1);
  background: rgba(255, 255, 255, 1);
}
.confirm-popup-batch-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirm-popup-selected-option span {
  font-size: 16.23px;
  font-weight: 400;
  padding: 4px;
  border-radius: 15px;
  cursor: pointer;
}
.confirm-popup-batch-option span {
  cursor: pointer;
  font-size: 16.23px;
  font-weight: 400;
  padding: 4px;
  border-radius: 15px;
}

.confirm-popup-batch-options {
  width: 22.4%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 4.06px;
  background: white;
  border: 1.35px solid rgba(221, 221, 221, 1);
  position: absolute;
}
.formatted-date {
  font-size: 18.94px;
  font-weight: 400;
}
.confirm-popup-fee-details h1,
h2,
p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.confirm-popup-fee-details h1 {
  font-size: 24.35px;
  font-weight: 600;
  color: rgba(255, 122, 0, 1);
}
.confirm-popup-fee-details h2 {
  font-size: 18.94px;
  font-weight: 500;
  color: rgba(72, 72, 72, 1);
}
.confirm-popup-fee-details p {
  font-size: 18.94px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.confirm-poup-enroll-now-button {
  width: 100%;
  height: 4rem;

  font-size: 18.94px;
  font-weight: 600;
}
.confirm-popup-modal-container {
  width: 78rem;
 height: 35rem;
  margin: 11rem 0 0 31rem;
}

@media (max-width: 1366px)and (max-height: 768px) {
  .confirm-popup-modal-container {
    width: 59rem;
    height: 35rem;
    margin: 6rem 0px 0px 14rem;
  }
  .confirm-popup-student-details p {
    font-size: 19px;
  }
}
@media (min-width: 1920px) and (min-height: 1080px) {
  .confirm-popup-modal-container {
    width: 78rem;
    height: 35rem;
     margin: 11rem 0 0 28rem;
  }


}
