.cart-container *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.cart-container{
    padding: 0.8rem;
    display: flex;
    justify-content: space-between;
}
.cart-detail{
    flex: 0 0 70%;
}
.cart-detail ul{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.cart-detail ul li{
    display: flex;
    align-items: center;
    border: 1px solid #DFE0E1;
    border-radius: 0.4rem;
    padding: 0.8rem;
    justify-content: space-between;
    
}
.cart-detail ul li .image-details{
    display: flex;
    align-items: center;
    width: 78%;
    gap: 2rem;
}
.cart-detail ul li .image-details img{
    width: 12%;
}
.image-details .quantity{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.image-details .quantity .button{
    background: #FFF2E6;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.2rem 0.5rem;
    font-weight: 700;
}
.image-details div p:nth-child(2){
    font-size: 0.8rem;
}
.image-details div button{
    background: none;
    color: #E51A1E;
    cursor: pointer;
    border: none;
}

.pricing{
    flex: 0 0 28%;
    border-left: 1px solid #DFE0E1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem;
}
.pricing .flex{
    display: flex;
    justify-content: space-between;
}
.pricing .discount{
    color: #00A991;
}
.pricing .total{
    color: #784409;
    font-weight: 600;
}
.checkout-button{
    background: #02720d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 1rem auto;
    color: #fff;
    padding: 0.4rem;
    border-radius: 0.4rem;
    cursor: pointer;
}