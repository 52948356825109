.career-track-courses-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.career-track-courses-container-header {
  display: flex;
  padding-bottom: 4rem;
  background: rgba(31, 4, 54, 1);
  padding-left: 16rem;
  padding-top: 5rem;
  flex-direction: column;
}
.career-track-courses-container-header h1 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 255, 132, 1);
  text-transform: uppercase;
}
.career-track-courses-container-header h2 {
  margin: 0;
  font-size: 44px;
  font-weight: 500;
  color: white;
}
.career-track-courses-container-header p {
  font-size: 20px;
  font-weight: 400;
  color: white;
  text-align: start;
  width: 50%;
}
.career-track-courses-container-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: rgba(250, 250, 250, 1);
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.career-track-courses-rating-section,
.career-track-courses-comment-section,
.career-track-courses-certificate-section {
  width: 75%;
}

.career-track-resume-course-card{
  margin-top: -5rem;
}