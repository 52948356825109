.gpt-container{
    border-left: 1px solid #c5b3d5;
    display: flex;
}

.visibleGpt{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gpt-heading-container{
    background: #C5B3D5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem;
}
.bytegpt-button{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    cursor: pointer;
}
.bytegpt-button .bytegpt-name{
    rotate: 90deg;
    color: green;
    text-transform: uppercase;
    font-weight: 700;
}
.gpt-heading{
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.gpt-heading img{
    width: 24%;
}
.gpt-heading h2{
    text-transform: uppercase;
}
.prompt-input{
    display: flex;
    justify-content: center;
}
.prompt-input input{
    width: 90%;
    padding: 1rem;
    background: #E3DAEB;
    border: none;
    border-radius: 0.4rem;
}

.bytegpt-bg{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 32%;
    z-index: -1;
}
.bytegpt-bg img{
    width: 100%;
}
.prompt-input-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    position: relative;
}
.prompt-dropdown{
    border-top: 1px solid #F2ECE6;
    box-shadow: 0px 5px 10px 2px #0000001A;
    width: 90%;
    margin: 0 auto;
    padding: 0.8rem;
    background: #fff;
}
.chapter-topic-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}
.dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #440978;
    padding: 0.4rem 1rem;
    gap: 1rem;
    border-radius: 2rem;
    color: #440978;
    font-size: 0.8rem;
    cursor: pointer;
}
.faq{
    width:96%;
    margin: 1rem auto;

}
.faq .faq-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00000080;
    margin-top: 0.8rem;
    cursor: pointer;
}
.faq .faq-item:hover{
    color: #000000;
}
.faq-item p{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.8rem;
}