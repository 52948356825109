/* Login.css */

.login-container {
  /* max-width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: auto; */

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  p {
    font-size: 1rem;
  }
}

.circle-one {
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: #ff1e1e35;
  filter: blur(60px);
  top: -20%;
  left: -10%;
  z-index: -1;
}

.circle-two {
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: #34936770;
  filter: blur(80px);
  bottom: -40%;
  right: -10%;
  z-index: -1;
}

.login-form-heading {
  margin-bottom: 3rem;
}

.login-form-heading h3 {
  font-size: 3rem;
}

.login-right-container {
  width: 50%;
}

.mobile-svg-image {
  display: none;
}

.login-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  border: none;
  gap: 1em;
}

/* custom underline */
.login-custom-underline {
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.login-custom-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 100%;
  height: 2px;
  background-color: #02720d;
}

.login-types {
  display: flex;
  gap: 3em;
  align-items: center;
}

.vertical-line {
  height: 10vw;
  /* background-color: #349367; */
  border: 1px solid #34936770;
  align-self: start;
}

.other-login-auth {
  align-self: start;
  margin: 1rem;
}

.google-auth {
  display: flex;
  gap: 0.3em;
  width: 12rem;
  height: 3rem;
  background-color: #02720d;
  font-size: 0.9rem;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.google-auth img {
  max-width: 2em;
}

.forget-and-login {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  gap: 0.7em;
}

.forget-and-login input[type="button"] {
  width: 40%;
  height: 3rem;
  border-radius: 10px;
  background-color: #02720d;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.login-form label {
  margin-bottom: 0.8em;
  font-weight: bold;
}

.login-form input {
  width: 90%;
  border: 1px solid;
  border-radius: 10px;
  padding: 1rem;
  padding-left: 1em;
  margin-top: 0.3rem;

  /* padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px; */
}

.login-form input:focus {
  outline: none;
}

/* .user-pass-input{
  width: 100%;
  border: 1px solid;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1em;
} */

.login-form button {
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.or-and-lines {
  display: none;
}

.asterisk {
  color: red;
}

/* Responsive Layouts */

@media (max-width: 850px) {
  .login-container {
    flex-direction: column;
  }

  .login-right-container {
    width: 80%;
  }

  .login-types {
    width: 80vw;
    flex-direction: column;
    gap: 0;
  }

  .line1 {
    width: 40%;
    /* height: 10vh; */
    height: 1px;
    background-color: grey;
    /* border: 1px solid; */
    /* transform: rotate(90deg); */
    /* position: absolute; */
    left: 0;
  }

  .or-and-lines {
    width: 80vw;
    display: flex;
    /* position: relative; */
    align-items: center;
    justify-content: space-between;
  }

  .line2 {
    width: 40%;
    height: 1px;
    background-color: grey;
  }

  .login-form-heading {
    display: flex;
    margin-bottom: 1rem;
  }

  .login-form-heading h3 {
    font-size: 2rem;
  }

  .login-form-heading p {
    font-size: 0.8rem;
    width: 90%;
  }

  .mobile-svg-image {
    display: contents;
  }

  .login-form {
    width: 100%;
  }

  .vertical-line {
    display: none;
    /* transform: rotate(90deg); */
    /* width: 100%;
   height: 0; */
  }

  .circle-one {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #ff1e1e35;
    filter: blur(60px);
    top: -20%;
    left: -30%;
    z-index: -1;
  }

  .circle-two {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #34936770;
    filter: blur(80px);
    bottom: -20%;
    right: -40%;
    z-index: -1;
  }

  .forget-and-login input[type="button"] {
    width: 100%;
  }

  .forget-and-login {
    margin-top: 0;
  }

  .sign-in-google {
    margin-top: 0;
  }
}