.mentorship-card-container{
  
    border-radius: 18.33px ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(45deg, #FFFFFF 2.67%, #FFFFFF 51.62%, #FFF2E6 97.33%);
    padding: 1rem 4rem 1rem 1rem;
    gap: 1rem;
}
.mentorship-card-time-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.mentorship-card-time-image-info{
    display: flex;
    align-items: center;
    justify-content: center;
}
.small-mentor-text{
    font-size: 14px;
    font-weight: 400;
}
.meeting-text{
font-size: 30px;
font-weight: 500;


}
.mentor-name{

font-size: 18px;
font-weight: 400;

}
.mentorship-card-mentor-container{
    display: flex;
align-items: center;
width: 100%;
margin-top: -1rem;
}
.mentorship-card-clock-image{
    height: 14px;
    margin-right: 7px;
    margin-top: -9px;
}
.mentorship-card-start-time{
    background: rgba(197, 179, 213, 1);
   
    padding: 10px ;
    border-radius: 10px ;
    margin-right: -2rem;
}
.mentorship-card-mentor-text-container{
    margin-left: 1rem;
}
.mentorship-card-mentor-text-container{
    display: flex;
    flex-direction: column;
}
.mentorship-card-buttons{
    display: flex;
    gap: 1rem;
}
.mentorship-card-button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
}

@media (min-width: 1920px) and (min-height: 1080px) {
    .mentorship-card-container{
        max-width: 24rem;
    }
    .mentorship-card-buttons{
        padding-left: 3rem;
    }
}


@media (max-width: 1366px)and (max-height: 768px) {
    .mentorship-card-start-time {
        margin-right: -1rem;
    }
    .meeting-text{
        font-size: 25px;
        font-weight: 500;  
    }
    .mentorship-card-mentor-container, .mentorship-card-container{
width: 89%;
    }
    .mentorship-card-time-container{
        width: 89%;
    }
    .mentorship-card-container{
        padding: 1rem 2rem 1rem 1rem;
    }
    .mentorship-card-button{
        width: 100px;
    }
}

