.questions-section{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}



.paper-container{
    border: 1px solid #DFE0E1;
    border-radius: 0.8rem;
}



.paper-title{
    border-bottom: 1px solid #ECE6F2;
    background: #E3DAEB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.8rem 0.8rem 0 0;
    padding: 0.8rem;
     button{
        padding: 0.4rem;
        border-radius: 0.4rem;
        background:  #fff;
        border: 1.5px solid #C5B3D5;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        font-size: 0.8rem;
        color: #00000090;
        cursor: pointer;
    }
}
.paper-title div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    cursor: pointer;
    gap: 0.4rem;
    padding: 0.6rem;
    border-radius: 0.6rem;
}
.questions-container{
    padding: 1rem;
    height: 70vh;
    overflow-y: scroll;
}
.questions-container ul{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    overflow-y: scroll;
    height: inherit;
}
.questions-container ul::-webkit-scrollbar{
    display: none;
}
.solution{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.6rem;
}
.questions-container .solution button{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    background: #02720d;
    border: none;
    padding: 0.8rem;
    border-radius: 0.4rem;
    cursor: pointer;
    color: #fff;
}
.question-item{
    border: 1px solid #EBE3DA;
    padding: 1rem;
    border-radius: 0.4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.question-item ol{
    display: flex;
    align-items: center;
    gap: 4rem;
    padding-left: 1rem;
}
.question-item ol li{
    list-style: upper-alpha;
    font-size: 0.8rem;
}
.tags-container{
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    gap: 0.8rem;
    margin: 0rem 0rem 1rem 0rem;
    
}
.tags-container span{
    padding: 0.4rem 0.8rem;
    border-radius: 2em;
}
.questions-repeat{
    display: flex;
    align-items: center;
}
.solution span{
    background: #FFE8A1;
    padding: 0.2rem;
    font-size: 0.8rem;
    border-radius: 0.2rem;
}