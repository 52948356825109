.applied-job-card{
height: 15.625rem;
padding:0.5rem;
border-radius: 10.79px ;
opacity: 0px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(11, 174, 219, 1);
}
.applied-jobs-card-company-details{
    background: rgba(234, 244, 255, 1);
    padding:0.5rem;
    width: 230px;
height: 187px;

border-radius: 10px ;

}
.applied-jobs-status-details-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    cursor: pointer;

}
.applied-jobs-status{
   display: flex;
   align-items:center ;
   justify-content: center;
    font-size: 12px;
    font-weight: 400;
   
    
    height: 25px;
    padding: 4px 12px 3px 13px;
    border-radius: 20px; 
}
.applied-job-details-btn{
    background: rgba(4, 61, 77, 1);
    display: flex;
    align-items:center ;
    justify-content: center;
font-size: 14px;
font-weight: 500;
  padding: 4px 13px 3px 13px;
    border-radius: 5px ;    
    color: white;
}
.applied-company-name{
    
    font-size: 16px;
    font-weight: 400;
   
    
}
.applied-company-role{
   text-align: left;
    font-size: 18px;
    font-weight: 700;
}
.applied-jobs-status.shortlisted {
    color: black; 
  }
  
  .applied-jobs-status.other {
    color: white;
  }