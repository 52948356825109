.meeting-details-left-container {
  width: 43rem;
padding-bottom: 2rem;
  border-radius: 20px;
  border: 1px solid rgba(236, 230, 242, 1);
  background: rgba(255, 255, 255, 1);
}
.meetings-details-left-header-main {
  display: flex;
}
.meetings-details-left-header-image {
  height: 4rem;
  width: 5rem;
  background-color: black;
}
.meetings-details-left-header-main {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  background: rgba(236, 230, 242, 1);
  border-radius: 20px 20px 0px 0px;
}
.meetings-details-left-header-main-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.meeting-details-left-name {
  font-size: 18px;
  font-weight: 400;

  color: rgba(0, 0, 0, 1);
}
.meetings-details-left-header-main-mentorship-type {
  font-size: 30px;
  font-weight: 500;
}
.meetings-details-left-below-header-container {
  height: 100px;
  border-radius: 0px 0px 020px 20px;
  border: 1px solid rgba(197, 179, 213, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7rem;
  padding-right: 7rem;
  border-top: none;
}
.meeting-details-left-timing-text-image-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.meeting-details-left-timing-text-container {
  display: flex;
  flex-direction: column;
}
.meeting-details-left-rate-container {
  display: flex;
  align-items: center;
  border: 1px solid rgba(7, 184, 24, 1);
  width: 80px;
  height: 40px;
  border-radius: 10px;
  justify-content: center;
}
.meeting-details-left-details-container {
  padding-left: 2rem;
  padding-right: 2rem;
}
.meetings-details-left-chat-line {
  font-size: 16px;
  font-weight: 400;
}
.meeting-details-left-chat-points-section {
  display: flex;
}
.meeting-details-left-chat-points-list-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}
.meeting-details-left-testimonials-heading-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meeting-details-left-all-testimonials-container {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 20px;
}
.meeting-details-left-testimonials-container{
  display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}
.meeting-details-left-testimonials-heading-text{

font-size: 22px;
font-weight: 600;


}
.meeting-details-left-single-testimonial-container {

  border-radius: 10px;
  border: 1px solid rgba(68, 9, 120, 1);
  margin-bottom: 10px;
  padding: 10px;
  flex: 0 0 auto;
  width:30rem;
  margin-right: 20px;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-right: 1rem;
}
.meeting-details-left-testimonial-navigation{
  display: flex;
}
.meeting-details-left-single-testimonial-heading-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.meeting-details-left-single-testimonial-about-paragraph-container {
  margin-top: 0.5rem;

font-size: 14px;
font-weight: 400;


}
.meeting-details-left-selected-meeting-details {
  width: 200px;
  height: 100px;
  background: rgba(227, 218, 235, 1);
  border-radius: 12.8px;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  gap: 1.5rem;
}
.meeting-details-left-selection-container {
  margin-top: 2rem;
}
.meeting-details-left-selected-meeting-popular-button {
  padding: 8px;
  border-radius: 10px;
  opacity: 0px;
  background: rgba(218, 243, 250, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  width: 42px;
  height: 17px;
}
.meeting-details-left-single-testimonial-author-name{

font-size: 18px;
font-weight: 600;

}
.meeting-details-left-selected-meeting-rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  padding: 8px;
  font-size: 13px;
  gap: 10px;
  border-radius: 10px;
  width: 42px;
  height: 17px;
}
.meeting-details-left-selected-meeting-popular-rating-container {
  display: flex;
  gap: 0.5rem;
}
.meeting-details-left-selected-meeting-mentorship-type {
  font-size: 20px;
  font-weight: 500;
}
.meeting-details-left-timing-text-container p{
font-size: 14px;
font-weight: 400;


}
.meeting-details-left-rate-text{

font-size: 16px;
font-weight: 700;

color: rgba(7, 184, 24, 1);
}

@media (max-width: 1366px)and (max-height: 768px) {
  .meeting-details-left-container {
    width: 25rem;
  }

  .meetings-details-left-below-header-container { 
    padding-left: 3rem;
    padding-right: 3rem;

}
}