.create-event-button{
    background: rgba(9, 120, 68, 1);
    border: none;
    color: white;
    height: 2rem;
    width: 5rem;

}
.create-event-button span{
    font-size: 11.26px;
    font-weight: 600;
}