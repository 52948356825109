.booked-session-page{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

.booked-session-page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.booked-session-page-header h1{
font-size: 30px;
font-weight: 500;
}
.booked-session-history{
    border: 1px solid rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
font-size: 16px;
font-weight: 500;
}