.loader-container {
  width: 75vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-one {
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 30, 30, 1);
  border-radius: 50%;
  transform: scale(0.1);
  animation: redLoader .7s linear infinite;
}
.loader-two {
  width: 3rem;
  height: 3rem;
  background-color: rgba(63, 169, 89, 1);
  border-radius: 50%;
  transform: translate(-100%);
  animation: greenLoader .7s linear  infinite;
}

@keyframes redLoader {
  50% {
    transform: translate(80%) scale(0.5);
  }
  100% {
    transform: translate(0) scale(1);
  }
}

@keyframes greenLoader {
  50% {
    transform: translate(-160%) scale(0.5);
  }
  100% {
    transform: translate(-80%) scale(0.1);
  }
}
