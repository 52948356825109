.score-container *{
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}
.score-container{

.bac-assessment-header {
    background: #b0e4dd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;

    .image-title {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      gap: 0.8rem;
      img {
        width: 12%;
      }
    }
  }

  .score-content{
    width: 80%;
    margin: 1rem 0.8rem;

    .back{
    background: #E6F6F4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  0.4rem;
    width: fit-content;
    cursor: pointer;
    gap: 0.4rem;
  }

  .assessment-name-report{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .name-date{
        display: flex;
        align-items: center;
        gap: 1rem;

        .name{
            span{
                color: #02720d;
            }
        }

        .date{
            color: #02720d;
        }
    }
    .report{
        display: flex;
        align-items: center;
        background: #02720d;
        color: #fff;
        width: fit-content;
        padding: 0.4rem 0.8rem;
        border-radius: 0.4rem;
        gap: 0.8rem;
    }
  }

  .assessment-table {
  width: 50%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.assessment-table th, .assessment-table td {
  padding: 0.8rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.assessment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.assessment-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.assessment-table tbody tr:hover {
  background-color: #ddd;
}

.score{
    
        margin-top: 1rem;
        display: flex;
        align-items: center;
        gap: 0.8rem;
    p{
        color: #003B33;
    }
    span{
        color: #FF9800;
        font-size: 2rem;
        font-weight: 700;
    }
}

.rate{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
  }
  

  .star-active{
    color: #FF9800;
  }
  .star-inactive{
    color: #FF9800;
  }
  }