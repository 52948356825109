.student-progress-container {
  width: 75vw;
  height: 100%;
  margin: 2rem 0 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.all-progress-card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.circular-progress-container {
  width: 20%;
  box-shadow: 0 0px 5px 5px rgb(56 56 56 / 24%);
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.circular-progress {
  align-self: center;
}

.circular-progress-and-stats {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.courses-assignments-stats {
  width: 80%;
  display: flex;
  flex-direction: column;
  width: 20;
  box-shadow: 0 0px 5px 5px rgb(56 56 56 / 24%);
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  gap: 1rem;
}

.prog-clip {
  position: absolute;
  right: 0;
  bottom: -1rem;
}

.prog-clip img {
  width: 7.5rem;
}

.assignment-stats {
  width: 90%;
  display: flex;
  gap: 2rem;
  position: relative;
  align-self: center;
}
.assignment-stats p {
  text-align: center;
}

.progress-number {
  font-size: 3rem;
  font-weight: bold;
}

.chapter-and-challenges {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.chapter-done-and-left,
.challenges-container {
  width: 50%;
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0px 5px 5px rgb(56 56 56 / 24%);
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
}

.total-and-completed,
.total-and-completed {
  width: 50%;
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.total-and-completed,
.total-and-completed p {
  text-align: center;
}

.analysis-container {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0px 5px 5px rgb(56 56 56 / 24%);
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
}

.analysis-container h5 {
  margin: 0;
}

.student-analysis,
.ai-analysis {
  width: 50%;
}

.chat-analysis {
  display: flex;
  gap: 1rem;
  border: 2px solid rgba(52, 147, 103, 0.1);
  background-color: rgba(52, 147, 103, 0.1);
  border-radius: 10px;
  padding: 1rem;
  position: relative;
  padding-left: 2rem;
}

.chat-analysis p {
  font-size: 0.7rem;
}

.greenlight {
  position: absolute;
  width: 0.8rem;
  height: 100%;
  background-color: #3fa959;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bot-image {
  padding: 1rem;
  background-color: lightgray;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis-list ul li {
  font-size: 0.8rem;
}

/* verticle line */
.verticle-line {
  height: 5rem;
  width: 0.1rem;
  background-color: lightgray;
}


.student-analysis{
  height: 100%;
}