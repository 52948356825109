.bac-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bac-container {
  .bac-assessment-header {
    background: #b0e4dd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;

    .image-title {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      gap: 0.8rem;
      img {
        width: 12%;
      }
    }
  }

  .bac-content-container {
    padding: 0 1rem;

    .welcome-section {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      h3 {
        color: #18032a;
      }

      p {
        font-size: 0.8rem;
        font-weight: lighter;
        color: #18032a;
        width: 48%;
        line-height: 1.5;
      }
      .input-prompt {
        position: relative;
        width: 40%;
        input {
          width: 100%;
          padding: 0.8rem;
          border-radius: 0.8rem;
          box-shadow: 0px 1px 10px 0px #0000001a;
          border: 1px solid transparent;
          border-image: linear-gradient(
              90.84deg,
              #009883 0.07%,
              #784409 17.13%,
              #440978 27.11%,
              #ff7b00 43.08%,
              #e86d0f 72.02%,
              #0a9dc5 96.37%
            )
            1;
          outline: none;
          &::placeholder {
            opacity: 0.5;
          }
        }
        .enter {
          position: absolute;
          bottom: 32%;
          right: 4%;
          font-size: 0.9rem;
          cursor: pointer;
        }
      }
    }
    hr {
  border: 0.5px solid transparent; 
  border-image-source: linear-gradient(
    90.84deg,
    #009883 0.07%,
    #784409 17.13%,
    #440978 27.11%,
    #ff7b00 43.08%,
    #e86d0f 72.02%,
    #0a9dc5 96.37%
  ); 
  border-image-slice: 1; 

}

  }
}
