.item-detail-section *{
    margin: 0;
    padding: 0;
    
}
.item-detail-section{
    background: #FAFAFA;
    padding: 1rem;
    position: relative;
}
.cart-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    padding: 0.8rem 0;
    position: relative;
}

.cart{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.4rem;
    cursor:pointer;
}
.scroll{
    height: 80vh;
    overflow-y: scroll;
}
.scroll::-webkit-scrollbar{
    display: none;
}
.product-container{
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;
}
.product-container::-webkit-scrollbar{
    display: none;
}
.product-container .left{
    display: flex;
    flex: 0 0 40%;
}
.product-container .left .variant-image{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.product-container .left .variant-image div{
    width: 32%;
}
.product-container .left .variant-image div img{
    width: 100%;
    cursor: pointer;
}
.product-container .left .hero-image{
    width: 70%;
    margin: 0 auto;
    text-align: center;
}
.product-container .left .hero-image img{
    width: 80%;
}
.right{
    border: 1px solid #0000001A;
    border-radius: 0.4rem;
    flex: 0 0 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.right .rating-review{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-weight: 600;
}

.right .description p{
    line-height: 1.5;
    font-weight: 400;
}
.right .price-quantity{
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.right .price{
    display: flex;
    align-items: center;
    color: #FF7B00;
    gap: 0.8rem;
}
.right .price span:nth-child(1){
    font-size: 1.5rem;
}
.right .price span:nth-child(2){
    color: #00000080;
    text-decoration: line-through;
}

.right .buttons{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.right .buttons div{
    background: #02720d;
    color: #fff;
    padding: 0.6rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: 600;
}
.right .buttons div:nth-child(1){
    background: #FAFAFA;
    color: #02720d;
    border: 1px solid #02720d;
}
.item-detail-section .banner{
    height: 50vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.4rem;
    margin: 2rem auto;
}

.about-author{
    margin: 1rem 0;
}
.about-author p{
    margin: 0.4rem 0;
    font-weight: 400;
}

.product-detail div{
    margin: 0.4rem 0;
}
.product-detail ul li{
    margin-left: 0.8rem;
}
.others{
    text-align: center;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.item-detail-location{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.item-detail-location p{
font-size: 20px;
font-weight: 400;
color: rgba(107, 52, 0, 1);
}
.update-location{
    color: rgba(255, 149, 51, 1);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}