.all-jobs {
  background: #ece6f2;
  padding-left: 2rem;
  padding-bottom: 6rem;
  padding-top: 2rem;
  margin-top: 2rem;
  margin-left: 2rem;
  border-radius: 1rem;
  margin-right: 2rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.all-jobs-container-page {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Two columns */
    gap: 0.5rem; /* Gap between grid items */
}
.all-jobs-container {
  background: #ece6f2;
  height: 25rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 2rem;
}

.all-jobs-text-heading {
  font-size: 30px;
  font-weight: 500;
}
.all-jobs-text-paragraph {
  width: 50%;
}
.all-jobs-await-search-bar {
  margin-top: 20px;
}
.all-jobs-next-section-arrow {
  width: 11rem;
  height: 6rem;
  opacity: 0px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-jobs-card-container {
  display: flex;
  gap: 1rem;
  margin-right: 2rem;
}

.all-jobs-text-container{
  display: flex;
  flex-direction: column;
}
.all-jobs-details-container{
  display: flex;
  align-items: center;
  justify-content: center;

}
.all-jobs-search-bar-filter-container{
  display: flex;
  gap: 1rem;
}
.job-page-filter{
  display: flex;
align-items: center;
justify-content: center;
padding: 8.5px 15.25px 10.5px 16.64px;
gap: 0px;
border-radius: 10px ;
border: 2px solid rgba(197, 179, 213, 1);
background-color: white;
cursor: pointer;
}