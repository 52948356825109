.text-editor{
    // width: 40%
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  .ql-snow.ql-toolbar {
    display: flex;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    justify-content: center;
  }

  .ql-editor {
    min-height: 18em;
  }