.calendar-event-details {
  position: absolute;
  background: white;
  right: 0rem;
  width: 34rem;
  height: 100vh;
  top: 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
}
.calendar-event-details h2 {
  font-size: 24px;
  font-weight: 700;
}
.calendar-event-details-body,
.calendar-event-details-first-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}
.calendar-event-details-first-container img {
  background: rgba(255, 255, 255, 1);
  padding: 0.5rem;
  border-radius: 10px;
}
.calendar-event-details-first-container h1,
.calendar-event-second-container-location-details h1,
.calendar-event-second-container-billing-details p {
  font-size: 16px;
  font-weight: 700;
}
.calendar-event-copy-button {
  color: rgba(14, 165, 233, 1);

  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.calendar-event-transcation-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.calendar-event-transcation-details p {
  font-size: 14px;
  font-weight: 700;
}
.calendar-event-transcation-details span {
  font-size: 14px;
  font-weight: 400;
}
.calendar-event-second-container-location-details p,
.calendar-event-second-container-billing-details span,
.calendar-event-second-container-location-details ul {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  opacity: 50%;
}

.calendar-event-second-container-location-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.5rem;
}
.calendar-event-second-container {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  gap: 1rem;
  width: 30rem;
}
.calendar-event-second-container h3 {
  font-size: 20px;
  font-weight: 700;
}
.calendar-event-second-container-billing-details p {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 14rem;
  gap: 0rem;
}
.calendar-event-second-container-billing-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2rem;
}
.calendar-event-second-container button {
    width: 28rem;
    height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background: rgba(7, 184, 24, 1);
  color: white;

  font-size: 20px;
  font-weight: 700;
}
