.meeting-details-calendar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  .meeting-details-calendar-body {
    background-color: white;
    display: flex;
    gap: 1rem;
    padding: 2rem;
  }

  .meeting-details-calendar-filter {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .meeting-details-calendar-filter-header {
      .meeting-details-calendar-filter-header-container {
        display: flex;
        gap: 2rem;
        align-items: center;
        .meeting-details-calendar-filter-header-date {
          display: flex;
          align-items: center;
          padding: 0.7rem;
          border: 1px solid rgba(216, 216, 216, 1);
          gap: 0.5rem;
          border-radius: 0.7rem;
        }
      }

      p {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .meeting-details-calendar-filter-body {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      h1 {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
      }
      .meeting-details-calendar-filter-body-buttons {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      button {
        padding: 0.5rem 1rem;
        border: 1px solid rgba(216, 216, 216, 1);
        color: black;
        cursor: pointer;
        border-radius: 0.25rem;
        font-size: 1rem;
        background: none;
        border-radius: 0.5rem;
      }
    }
  }

  .small-calendar {
    .small-calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .small-calendar-title {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .small-calendar-navigation-buttons {
        display: flex;
        gap: 0.5rem;

        .small-calendar-nav-button {
          background: none;
          border: none;
          cursor: pointer;

          .small-calendar-chevron-left,
          .small-calendar-chevron-right {
            font-size: 1.5rem;
          }
        }
      }
    }

    .small-calendar-days-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 0.5rem;

      .small-calendar-day-label,
      .small-calendar-day-button {
        text-align: center;
        font-size: 1rem;
      }

      .small-calendar-day-button {
        background: none;
        border: none;
        cursor: pointer;

        &.selected-day {
          background-color: green;
          color: white;
        }

        .small-calendar-day-number {
          display: block;
          padding: 0.5rem;
        }
      }
    }
  }
}
