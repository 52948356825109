.play-video-container{
    padding:  2rem ;
    /* margin:  0 auto; */
    display: flex;
    gap: 1rem;
}
.video-player video{
    width: 68rem;
}
.video-container{
    display: flex;
    flex-direction: column;
    width: 79%;
}
.video-back{
    display: flex;
    align-items: center;
    padding: 0.4rem;
    margin: 1rem 0;
    background: #FFC28A;
    width: fit-content;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;
}
.video-player-section .video-player{
    border-radius: 0.8rem;
}
.video-player video{
    border-radius: 0.8rem;
}

.like-share-comment{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
}
.like{
    padding: 0.8rem 2rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
.share{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    cursor: pointer;
}
.comment{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    cursor: pointer;
}
.video-action-background{
    background: #02720d;
    color: #fff;
}
.about-video-topic{
    border: 0.9px solid #00000033;
    padding: 0.8rem;
    border-radius: 0.4rem;
    width: 98%;
}
.about-video-topic .topic-info{
    font-size: 0.8rem;
    color: #00000080;
    margin-top: 0.8rem;
}
.video-comment-section{
    margin-top: 2rem;
    padding: 0.8rem;
    border: none;
    width: 112%;
}
.video-comment-area{
    margin: 1rem 0;
}
.video-comment-area input{
    width: 70%;
}
.video-comment-section .profile-name{
    gap: 1rem;
}
.library-video-upcoming{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.video-card-upcoming{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    flex: 0 0 10%;
    border-radius: 0.4rem;
    justify-content: space-between;
    position: relative;
    border: 1px solid #B0E4DD;
    gap: 1.2rem;
    cursor: pointer;
}
.video-card-image-section{
    object-fit: cover;
    width: 36%;
    border-radius: 0.4rem;
    height: 7rem;
}
.video-card-upcoming-section{
    display: flex;
    flex: 0 0 10%;
    border-radius: 0.4rem;
    position: relative;
    border: 1px solid #B0E4DD;
    gap: 1.2rem;
    cursor: pointer;
    width: 27rem;
}
.playicon-section{
    position: absolute;
    left: 11%;
    top: 16%;
    display: none;
}