.job-search-result-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.job-search input {
  outline: none;
  border: none;
  font-size: 1rem;
  width: 80%;
}
.job-search {
  width: 28rem;
  height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
}
.job-page-search-results {
  width: 25rem;
  position: absolute;
  padding: 20px 25px 20px 25px;
  border-radius: 0px 0px 20px 20px;
  gap: 0.5rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: start;
}
.job-page-search-results-heading {
  font-size: 18px;
  font-weight: 700;

  color: rgba(68, 9, 120, 1);
}
.job-search-result-content li {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 1366px)and (max-height: 768px) {
  .job-search {
    width: 21rem;
  }
}
