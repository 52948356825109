.available-item-section{
  margin-top: 0.8rem;
}
.chips-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 4rem 0;
  overflow-y: scroll;
}
.chips-container::-webkit-scrollbar{
    display: none;
}
.chips-container li{
  background: #F2ECE6;
  padding: 0.4rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  cursor: pointer;
  flex: 0 0 14%;
}

.chips-container li.active{
  color: #fff;
  background: #FF9533;
}

.item-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  gap: 0.8rem;
}
.item-container::-webkit-scrollbar{
  display: none;
}
.item-card{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.item-container a{
  background: #fff;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.4em;
  flex: 0 0 17.6%;
  cursor: pointer;
  height: 21rem;
}
.item-card .cover-image{
  width: 60%;
  margin: 0 auto;
}

.item-card img{
  width: 100%;
}
/* .item-card .name-price{
  
} */

.item-card .name-price p:nth-child(2){
  color: rgba(7, 184, 24, 1);
  font-weight: 600;
}

.name-price{
  display: flex;
  justify-content: space-between;
}
