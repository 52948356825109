.blog-search-result-content{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .blog-search input {
    outline: none;
    border: none;
    font-size: 1rem;
    width: 80%;
  }
  .blog-search {
    width: 28rem;
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
  }
  .blog-page-search-results{
    width: 25rem;
    position: absolute;
  padding: 20px 25px 20px 25px;
  border-radius: 0px 0px 20px 20px;
  gap: 0.5rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: start;
  }
  .blog-page-search-results-heading{
font-size: 18px;
font-weight: 700;
text-align: start;
color: rgba(68, 9, 120, 1);

  }
  .blog-search-result-content li{
    font-size: 18px;
    font-weight: 400; 
  }
  .blog-search{
    width: 28rem;
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
}
  