.byteconnect-container{
    /* width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2rem;
    margin-top: 2rem;
    background: #ECE6F2;
    border-radius: 10px; */
    padding: 2rem;
}

.byteconnect-forge-path-container, .byteconnect-booked-session-container{
    background: #ECE6F2;
   
    height: 25rem;
    border-radius: 10px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem 1.5rem 2rem;
}
.byteconnect-booked-session-container{
    margin-top: 1rem;
    flex-direction: column;
    align-items: start;
    box-shadow: 0px 2px 5px 0px rgba(68, 9, 120, 0.15);
}
.byteconnect-forge-path-text-heading{
    
font-size: 30px;
font-weight: 500;
text-align: start;


}
.byteconnect-forge-path-text-paragraph{
    width: 70%;
    text-align: start;
font-size: 18px;
font-weight: 300;

color: rgba(0, 0, 0, 1);

}
.byteconnect-booked-session-heading{
font-size: 30px;
font-weight: 500;


}
.byteconnect-forge-path-search-bar{
    margin-top: 20px;
}
.byteconnect-forge-path-card-container{
    display: flex;
    gap: 1rem;
    margin-right: 2rem;
}
.byteconnect-forge-path-next-section-arrow{
    width: 15rem;
    height: 6rem;
    /* border-radius: 60px 60px 60px 60px; */
    opacity: 0px;
    background: #FFFFFF;
        border-radius: 50%; /* This will make the element a perfect circle */
    display: flex;
    align-items: center;
    justify-content: center;
}
.byteconnect-forge-path-next-section-arrow:hover , .byteconnect-forge-path-next-section-arrow-two:hover{
    background-color: rgba(217, 242, 239, 1);
}
.byteconnect-booked-session-mentorship-cards{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    /* display: grid;
    margin: 0 auto;
    max-width: 96rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    /* gap: 0.5rem; */
    margin-top: 1rem;
    margin-bottom: 5rem;
}
.byteconnect-forge-path-next-section-arrow-two{
    width: 6rem;
    height: 6rem;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (min-width: 1920px) and (min-height: 1080px) {
    .byteconnect-forge-path-card-container {
        display: flex;
        gap: 2rem;
        margin-right: 2rem;
    }
}
@media (max-width: 1366px)and (max-height: 768px) {
    .byteconnect-container {
        margin-left: 1rem;
        margin-right: 1rem; /* Adjust margin to center the container */
    }
    
    .byteconnect-forge-path-card-container {
       
        margin-right: 1rem;
    }
    .byteconnect-forge-path-next-section-arrow {
        margin-top: 1rem; /* Add space above the arrow */
        width: 17rem; /* Adjust width */
        height: 4rem; /* Adjust height */
    }

    .byteconnect-booked-session-mentorship-cards:nth-child(3){
        display: none;
    }
    .byteconnect-forge-path-text-paragraph {
        width: 90%;
        text-align: start;
        font-size: 17px;
    }
    .byteconnect-forge-path-container{
        width: 69%;
    }
    .byteconnect-booked-session-container{
        width: 69%;
    }
}
