
  .search-bar {
    width: 29rem;
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
  }
  
  .search-bar input {
    outline: none;
    border: none;
    font-size: 1rem;
    width: 80%;
  }
  @media (max-width: 1366px)and (max-height: 768px) {
    .search-bar {
      width: 86%;
      height: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      text-align: center;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: white;
    }
    .experts-page-byteconnect-forge-path-search-bar {
      margin-right: 16rem;
  }
  
}