.day-container {
  /* border: 1px solid #e2e8f0; */
  display: flex;
  flex-direction: column;
}

.day-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: start;
}

.day-label {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.25rem;
  color: rgba(150, 150, 150, 1);
  padding-left: 0.4rem;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  width: 100%;
}

.day-number {
  color: rgba(150, 150, 150, 1);
  font-size: 18px;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem 0;
  text-align: center;
}

.current-day {
  background-color: rgba(255, 152, 0, 1);
  color: white;
  border-radius: 50%;
  /* width: 1.75rem; */
}

.events-container {
  flex: 1;
  cursor: pointer;
}

.event {
  background-color: #f7fafc;
  padding: 0.25rem;
  margin-right: 0.5rem;
  color: #4a5568;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.event:hover {
  background-color: #edf2f7;
}
