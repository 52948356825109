.student-card-container{
    width: 16rem; height: 21rem;
border-radius: 20px;
opacity: 0px;
display: flex;
align-items: center;
justify-content: center;
background: linear-gradient(45deg, #FFFFFF 2.67%, #FFFFFF 51.62%, #FFF2E6 97.33%);
flex-direction: column;
padding: 1rem;

}
.student-card{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.student-card-image1 img {
    max-width: 100%;
}
.student-card-text-name-role{
    gap: 1.5rem;
}
.student-card-text-designation{
    font-size: 16px;
    font-weight: 700;
    color: #FF9533;
    margin-top: -9px;
}
.student-card-text-name{
    font-size: 22px;
    font-weight: 600;
     color: #043D4D;

}
.student-card-text-role{
    font-size: 18px;
    font-weight: 300;
    color: #043D4D;
    margin-top: -10px;
}
.student-card-text-container, .student-card-text-name-role{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.student-card-images{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.student-card-image2{
    margin-top: -2rem;
}
.student-card-outer-container{
    display: flex;
    gap: 1rem;
} 

@media (min-width: 1920px) and (min-height: 1080px) {
    .student-card-container{
        max-width: 16.5rem;
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    
}
.student-card img {
    height: 10.5rem;
}
}

@media (max-width: 1366px)and (max-height: 768px) {

    .student-card-container {
        width: 13rem;
    height: 16rem;
    padding-top: 1rem;
    }
    .student-card img {
        height: 7.5rem;
    }
    .student-card-text-role {
        font-size: 15px;
      
    }
    .student-card-text-name {
        font-size: 18px;
       
    }

    .student-card-text-designation {
       
        margin-top: -2rem;
    }
}