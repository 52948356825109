.register-container {
  width: 100wh;
  display: flex;
  justify-content: space-around;
  align-items: center;
 

  p{
    font-size: 1rem;
  }
}

.term-and-condiiton link {
}

.term-and-condiiton button {
  width: 20%;
  height: 3rem;
  border-radius: 10px;
  background-color: #2d9d6e;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.register-right-container {
  width: 50%;
  height: 100vh;
  position: relative;
  margin-left: 8rem
  /* overflow: hidden; */
}

.register-form-heading {
  margin-bottom: 3rem;
}

.register-form-heading h3 {
  font-size: 3rem;
  margin-bottom: 0%;
}

.mobile-svg-image {
  display: none;
}

.register-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  border: none;
  gap: 1em;
}

.register-types {
  display: flex;
  gap: 3em;
  align-items: center;
}

.term-and-condiiton input[type="button"] {
  width: 40%;
  height: 3rem;
  border-radius: 10px;
  background-color: #02720d;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.register-form label {
  margin-bottom: 0.8em;
  font-weight: bold;
}

.register-form input {
  width: 90%;
  border: 1px solid;
  border-radius: 10px;
  padding: 1rem;
  padding-left: 1em;
  margin-top: 0.3rem;

  /* padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px; */
}

.register-form input:focus {
  outline: none;
}


.radio-area{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.radio-btn{
  width: 40%;
  height: 2rem;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  gap: 1em;
  font-size: .9rem;
  padding: 5px;
}

/* .fresher{
  width: 9rem;
} */

.experience{
  width: 45%;
}

.radio-btn input{
  width: 10%;
  margin: 0;
}

.radio-btn label{
  margin: 0;
}

.user-gender label,
.user-preference label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-preference-dropdowns {
  background-color: white;
  width: 96%;
  padding: 1rem;
  padding-left: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* responsive layout */

@media (max-width: 850px) {
  .register-container {
    flex-direction: column;
  }

  .register-right-container {
    width: 80%;
    transform: translateY(0);
  }

  .register-form-heading {
    display: flex;
    align-items: center;
  }

  .register-form-heading h3 {
    font-size: 2rem;
  }

  .register-form-heading p {
    font-size: 0.8rem;
    width: 90%;
  }

  .mobile-svg-image {
    display: contents;
  }

  .register-types {
    width: 80vw;
    flex-direction: column;
    gap: 0;
  }

  .register-form {
    width: 100%;
  }

  .experience-heading{
    display: none;
  }

  .term-and-condiiton p{
    font-size: .8rem;
  }

  .term-and-condiiton input[type="button"]{
    width: 100%;
  }
}
