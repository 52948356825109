.quick-notes{
    width: 70vw;
    height: 90vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 2rem;
    align-items: normal;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    gap: 1rem;
}
.topic{
    margin: .5em .5em;
}
.topic:hover{
    cursor: pointer;
}

.topic:active { font-weight: bold; }

.quick-link{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    border: 1px solid;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 1rem;
    transform: translateY(.1rem) translateX(-2rem);
    padding: .5rem;
    /* z-index: -1; */
    border-bottom: none;
}

.subject-notes{
    max-width: 100%;
    background-color: white;
    border-radius: 20px;
}

.typing-text {
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #000;
    animation: typing-animation 5s steps(4, end) forwards;
}

@keyframes typing-animation {
    0% {
        width: 0;
    }
    25% {
        width: 25%;
    }
    50% {
        width: 50%;
    }
    75% {
        width: 75%;
    }
    100% {
        width: 100%;
    }
}


.subject-notes-list{
    border-radius: 40px;
    /* border: 1px solid; */
    /* style={{ display: "flex", flexDirection:"column", gap:"1rem" }} */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;
    max-height: 100%    ;
    padding: 1rem;
}

.subject-notes {
    height: 85vh; /* Set the height of the container to 100% of the viewport height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: top;
  }
  