.success-container *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.success-container{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
}
.success-container img{
    width: 40%;
}
.success-container .delivery-date{
    background: #FFF2E6;
    border: 1px solid #FFA754;
    box-shadow: 0px 1px 2px 0px #44097826;
    font-weight: 600;
    padding: 0.8rem;
    
}
.success-container .delivery-information{
    background: #FFF2E6;
    color: #440978;
    width: 100%;
    text-align: center;
    padding: 0.4rem;
}
.contain{
    border: 1px solid #00000030;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 50vh;
    border-radius: 0.4rem;
}