/* Labels.css */

.label-header {
  color: #718096;
  font-weight: bold;
  margin-top: 1rem;
}

.label-item {
  display: block;
  margin-top: 0.75rem;
}

.checkbox {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.blue-checkbox {
  color: #4299e1;
}

.green-checkbox {
  color: #48bb78;
}

.red-checkbox {
  color: #f56565;
}

.purple-checkbox {
  color: #9f7aea;
}

.gray-checkbox {
  color: #a0aec0;
}

.orange-checkbox {
  color: #ed8936;
}

.label-text {
  color: #4a5568;
  text-transform: capitalize;
}
.upcoming-events-container {
  width: 24rem;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.upcoming-events-container h2 {
  background: rgba(255, 255, 255, 1);
  padding: 1rem;
  font-size: 18px;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  height: 3rem;
  width: 3rem;
}
.upcoming-events-details-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.upcoming-events-details-container h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.upcoming-events-details-container p {
  font-size: 18px;
  font-weight: 400;
}
