.career-track-courses-container-module-card {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(235, 227, 218, 1);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 78rem;
    border-radius: 1rem;
  }
  .career-track-courses-container-module-card-header,
  .career-track-courses-container-module-toggle-details-continue-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .career-track-courses-container-module-card-header-text {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .career-track-courses-container-module-card-header-text h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 10px;
    background: rgba(230, 246, 244, 1);
  }
  .career-track-courses-container-module-card-header-text h1 {
    font-size: 22px;
    font-weight: 600;
  }
  .career-track-courses-container-module-card-header-description-container {
    display: flex;
    flex-direction: column;
  }
  .career-track-courses-container-module-card-header-description-container p {
    font-size: 18px;
    font-weight: 400;
  }
  .career-track-courses-container-module-progress-bar{
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .career-track-courses-container-module-progress-percentage {
    font-size: 20px;
    font-weight: 700;
  }
  .career-track-courses-container-module-toggle-details-button {
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    border: none;
    background: none;
  }
  .career-track-courses-container-module-toggle-continue-button {
    background: rgba(7, 184, 24, 1);
    width: 10rem;
    font-size: 18px;
    font-weight: 700;
    padding: 15.6px 52px 15.6px 52px;
    border-radius: 11.7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: white;
    cursor: pointer;
  }
  .hidden-details {
    opacity: 0.5; /* Decrease opacity when details are hidden */
  }
  
  /* Styles for checkboxes */
  .checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .point-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .completed {
    background-color: green; /* Green tick for completed points */
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .current {
    background-color: green; /* Green circle for current point */
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .incomplete {
    background-color: rgba(197, 179, 213, 1); /* Circle for incomplete points */
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  /* Styles for arrow */
  .arrow {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
  }
  .point-item-checkbox-text-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .career-track-courses-point-container {
    margin-top: 2rem;
    /* margin-left: -2rem; */
  }  
  .course-topic-details-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  @media (max-width: 1366px)and (max-height: 768px) {
    .career-track-courses-container-module-card {
     
      width: 54rem;
    
  }
  .program-details p {
   
    font-size: 19px;

}
  }