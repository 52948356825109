.blogs-filter{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8.5px 15.25px 10.5px 16.64px;
    border-radius: 10px;
    border: 2px solid rgba(197, 179, 213, 1);
    background-color: white;
    cursor: pointer;
    width: 5rem;
}