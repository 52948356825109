/* Month.css */

.month-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.month-containertime-slots {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 6rem;
  font-size: 14px;
  font-weight: 400;
  color: rgba(150, 150, 150, 1);
}

.month-containertime-slot {
  font-size: 0.75rem;
  color: #4b5563; 
  margin-right: 0.5rem;
}

.month-containerdays-container {
  display: flex;
  flex-direction: column;
  background: white;
 
  border-radius: 1rem;
}

.month-containerdays-row {
  display: flex;
}
.month-containerday-other-box{
  border: 1px solid #e2e8f0;
  width: 9rem;
  height: 5rem;
}
.calender-month-containertime-slots{
  display: flex;
   gap: 1rem ;
   background: rgba(255, 255, 255, 1);
padding: 1rem;
   box-shadow: 0px 3.6px 28.77px 0px rgba(170, 170, 170, 0.03);
   border: 1px solid rgba(242, 236, 230, 1);
}
.calendar-view-buttons {
  display: flex;
  align-items: center;
}
.calendar-view-buttons button {
  font-size: 16px;
  font-weight: 400;
}
.month-containerday-container {
  flex-grow: 1;
}
.month-containerday-box {
  border: 1px solid #e2e8f0; 
  height: 6rem;
  width: 9rem;
}
.month-containerday-box-week{
  border: 1px solid #e2e8f0; /* Border color for each day */
  
  width: 10rem;
  height: 12rem;
}
/* CalendarHeader.css */

.calendar-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reset-button {
  font-size: 16px;
  font-weight: 300;
  border: none;
  background: none;
  cursor: pointer;
}

.navigation-button {
  background: none;
  border: none;
  cursor: pointer;
}

.chevron-icon {
  font-size: 1.5rem;
  color: #888;
}

.month-label {
  margin-left: auto;
  font-size: 1.25rem;
  font-weight: bold;
  color: #888;
}

.calendar-header-navigate-button-container {
  display: flex;
  align-items: center;
 
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.calendar-search {
  width: 15rem;
  height: 3rem;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding-left: 1rem;
  border: none;
  background: rgba(244, 244, 245, 1);


}
.calendar-search input {
  outline: none;
  border: none;
  font-size: 1rem;
  background: none;
}
