.job-details-container {
  margin-left: 2rem;
  padding-right: 2rem;
border-radius: 20px;
border: 1px solid rgba(217, 242, 239, 1);
box-shadow: 0px 2px 5px 0px rgba(68, 9, 120, 0.15);
padding-left: 2rem;
padding-bottom: 2rem;
padding-top: 2rem;
margin-top: 1rem;
margin-left: 2rem;
margin-right: 2rem;
  display: flex;
  flex-direction: column;
 justify-content: center;
  align-items: center;
  gap: 1em;


}

.go-back {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

.job-img {
  width: 6rem;
  height: 6rem;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  top: 2rem;

}

.job-img img {
  width: 95%;
  height: 75%;
  object-fit: cover;
  overflow: hidden;
}

.job-details-and-descriptions {
  width: 100%;
  display: flex;
  gap: 2em;
}

.job-detail {
  display: flex;
    align-items: flex-end;
    justify-content: space-between;
  height: 200px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px ;
  opacity: 0px;
  background: linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%);

}

.job-detail h2 {
  margin: 0;
}

.job-company-name {
  font-size: 1.5rem;
  color: #cd2626;
  text-transform: capitalize;
}

.job-location {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 1rem;
}

.job-location i {
  color: #349367;
}

.job-description {
  width: 85%;
  font-size: 0.9rem;
}

.job-description p {
  text-align: center;
  margin: 0;
}

.job-tags {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.job-details-page-company-name{
  text-align: left;
}

.job-tags h3 {
  padding: 5px 10px;
  background-color: #d6e9e1;
  border-radius: 10px;
  font-size: 0.8rem;
}

.visit-cmpny {
  width: 70%;
  border: 1px solid #349367;
  border-radius: 10px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.visit-cmpny:hover{
  background-color: #3fa959;
  color: white;
}

.visit-cmpny link {
  margin: 0;
  color: #3fa959;
  text-decoration: none;
}

a {
  /* color: #3fa959; */
  text-decoration: none;
  font-weight: bold;
}

/* job details right container styling  */
.jobs-complete-details, .job-salary-container, .company-overview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  border: 1px solid rgba(213, 197, 179, 1);
  padding-left: 1.5rem;
  padding-right: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  border-radius: 10px;
}
.job-salary-container{
  padding-bottom: 3rem;
  padding-top: 2rem;
}
.job-matching{
  width: 100%;
  /* display: flex; */
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.job-title-and-descripiton {
  background-color: white;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  margin-top: 1.6rem;
  padding-right: 1.5rem;
}
.job-details-posted-on-date{
  
font-size: 14px;
font-weight: 400;
color: rgba(47, 46, 65, 0.5);


}

.job-title-and-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title h2 {
  
  font-size: 44px;
  font-weight: 700;
 
  text-align: center;
  
  margin: 0;
}

.title p {
 
  font-size: 20px;
  font-weight: 400;
 
  
  margin-top: 0;
}

.job-action-btn {
  flex-direction: column;
  display: flex;
  /* width: 45%;
    justify-content: space-around; */
  align-items: center;
  gap: 0.5rem;
}

.unlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.5em;
  border-radius: 10px;
  color: white;
  background-color: #3fa959;
  padding: 0 1.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 0.7rem;
}

.save-job {
  border: 1px solid #3fa959;
  padding: 0 1.5rem;
  border-radius: 10px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
}

.jobs-descript h2 {
  text-align: start;
  margin-top: 0;
  margin-bottom: 0.5rem;
font-size: 22px;
font-weight: 600;


}
.jobs-descript p {
  text-align: start;
 padding-left: 1rem;
font-size: 16px;
font-weight: 400;


}

.qualifications,
.responsibilities li {  
  font-size: 16px;
  font-weight: 400; 
}
.qualifications,
.responsibilities h2, ul {
  
margin-top: 0; 
margin-bottom: 0.5rem;
}
.qualifications h2{
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.job-details-byte-code-image{
  height: 4rem;
  width: 8rem;
  background-color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.job-location-between-dot{
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50px;
  background-color: grey;
}
.jobs-unlock-button{

cursor: pointer;
padding: 12.24px 39.45px 12.24px 39.45px;
gap: 0.5rem;
border-radius: 6.8px ;
display: flex;
align-items: center;
justify-content: center;
background: rgba(7, 184, 24, 1);
color: white;
}



/* salary container */
.job-salary-amount-container{
  display: flex;
  flex-direction: column;
}
.job-salary-image-amount-box{
  display: flex;
}
.average-salary-text{
  margin-left: 1rem;
font-size: 20px;
font-weight: 400;

}
.job-salary-image-amount-box h1{
  margin-top: 0;
  margin-bottom: 0.5rem;
font-size: 44px;
font-weight: 500;
}
.job-salary-industry-employment-contact-container{
  display: flex;
  gap: 5rem;
}
.salary-industry-employment-contact-boxes{
  display: flex;
  gap: 1rem;
}
.salary-industry-employment-contact-boxes h1{
 
font-size: 22px;
font-weight: 600;

margin: 0;
}
.salary-industry-employment-contact-boxes p{
 
  font-size: 20px;
  font-weight: 400;
  
  margin: 0;
  }

.job-details-sub-container{
  display: flex;
  flex-direction: column;
}
.job-details-about-company-container{
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.job-details-about-company-name-logo-employees{
  display: flex;
  align-items: center;
}
.job-details-about-company-name-logo-employees img{
  width: 5rem;
  height: 7rem;
}
.job-details-about-company-name-employees-container{
  display: flex;
  flex-direction: column;
}
.job-details-about-company-name-employees-container h1{
  margin: 0;
  
font-size: 18px;
font-weight: 700;


}
.job-details-about-company-name-employees-container p{
 
  font-size: 16px;
  font-weight: 400;
  
}
.job-details-about-company-details-para p{
font-size: 16px;
font-weight: 400;
text-align: left;

}
.company-overview h2{
margin: 0;

font-size: 22px;
font-weight: 600;
}
.company-overview{
  gap: 0.5rem;
}
.jobs-details-page-recommended-jobs{
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.jobs-details-page-recommended-jobs h1{
  
  font-size: 22px;
  font-weight: 600;

  
}
.jobs-details-page-recommended-jobs-cards{
  display: flex;
  gap: 1.5rem;
}
.job-details-page-assessment-page-instruction-heading{
  
font-size: 22px;
font-weight: 600;
margin: 0;
}
.job-details-page-assessment-page-instruction-details{
  display: flex;
  flex-direction: column;
  align-items: start;
}
.job-details-page-assessment-page-details{
  
font-size: 18px;
font-weight: 400;


}
.job-details-page-assessment-page-instruction-points{
  align-items: start !important;

}
.job-detaails-page-take-assesment-button{
  width: 14rem;
height: 5rem;
padding: 12.15px 39.15px 12.15px 39.15px;
border-radius: 6.75px;
background: rgba(7, 184, 24, 1);
font-size: 18px;
font-weight: 500;
color: white;
display: flex;
align-items: center;
justify-content: center;
border: none;
cursor: pointer;
}
.job-details-page-assessment-page{

}