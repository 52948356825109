

.candidate-form-container {
  /* width: 100vw; */
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.profile-routes {
  width: 100%;
}


