.all-career-track-card-page-container{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background: rgba(176, 228, 221, 1);
    box-shadow: 0px 2px 5px 0px rgba(68, 9, 120, 0.15);
margin: 2rem;
border-radius: 1rem;
}
.dash-subject-progress-container-career{
    margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
}