.assignment-test-container {
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 1rem;
  margin-top: 2rem;
}

.assignment-chapter {
  font-weight: bold;
}

.assignment-questions-numbers-box {
  width: 70vw;
  height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.left-side-arrow,
.right-side-arrow {
  width: 7%;
  height: 100%;
  background-color: #cdf5ba;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.question-numbers ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}

.question-numbers {
  width: 2rem;
  height: 2rem;
  background-color: #d6e9e1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.question-numbers.active {
  background-color: #2f2e41;
  color: #fff;
  cursor: pointer;
}

.assignment-question-contianer {
  /* height: 100%; */
  width: 70vw;
  margin-left: 3rem;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.questions-change {
  width: 100%;
  /* height: 30%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #cdf5ba;
  padding: 0.5rem 0;
}

.response-and-question {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.switch-question {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  /* margin-right: .5rem; */
}

.clear-response {
  background-color: #2f2e41;
  border-radius: 10px;
  color: white;
  padding: 0.4rem;
  cursor: pointer;
}

.switch-question i {
  background-color: #349367;
  padding: 0.3rem;
  border-radius: 5px;
  cursor: pointer;
}

.question-details-and-options {
  width: 100%;
  display: flex;
  /* padding: .5rem; */
  justify-content: space-around;
  padding: 1rem;
  background-color: white;
  padding-bottom: 5rem;
}

.question-detail {
  width: 70%;
}

.answer-options {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
}

.ans {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #d6e9e1;
  cursor: pointer;
  gap: 1rem;
}

.ans.selected {
  background-color: #349367;
  color: #fff;
}

.ans h3 {
  width: 5%;
  margin-left: 0.2rem;
  font-size: 0.8rem;
  background-color: white;
  color: #349367;
  padding: 0.3rem 0.6rem;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ans.selected-ans {
  background-color: #2f2e41;
  color: white;
}

.ans p {
  width: 80%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* padding-top: .3rem; */
}

/* showig the red color to the question number if we are moving forward wotout selecting any option */
.assignment-questions-numbers-box .question-numbers.unanswered {
  background-color: red;
  color: white;
}

.option-selection {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* assignment actions */
.assignment-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #cdf5ba;
  padding: 1rem 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.assignment-action-btns {
  /* width: 100%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
}

.assignment-submit {
  display: flex;
  gap: 0.5rem;
}

.save-and-next {
  background-color: #3fa959;
  color: white;
  padding: 0.4rem;
  border-radius: 10px;
  cursor: pointer;
}

.review-mark {
  background-color: #2f2e41;
  padding: 0.4rem;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.assignment-submit {
  background-color: #349367;
  color: white;
  padding: 0.4rem;
  border-radius: 10px;
  cursor: pointer;
}


.question-numbers.marked-for-review {
  background-color: red;
}

.question-numbers.answered {
  background-color: #3FA959;
}