.class-player-container{
    width: 80%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 1rem;
    margin: 1rem;
}

.go-back-and-title{
    width: 95%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

/* .recording-name{
    align-self: center;
} */

.recording-lecture-name{
    width: 40%;
    font-weight: bold;
}


.react-player{
    border-radius: 20px;
    overflow: hidden;
}

video{
    object-fit: cover;
}

.recording-actions{
    display: flex;
    gap: 1rem;
}

.actn-btn{
    background-color: #349367;
    padding: .3rem  .8rem;
    border-radius: 20px;
    color: white;
    font-size: .8rem;
    text-align: center;
    cursor: pointer;
}

.create-class-notes{
    width: 80vw;
    background-color: white;
    border-radius: 20px;
}

.notes-heading{
    display: flex;
    margin: 1rem;
    gap: .5rem;
    font-weight: bold;
}

.notes-area{
    display: flex;
    justify-content: center;
    align-items: center;
}


.notes-textarea{
    width: 95%;
    outline: none;
    border: none;
    resize: none;
    align-self: center;
}