.blog-recommend {
  display: flex;
  margin: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
  padding: 1rem;
  gap: 1rem;
}

.dash-blog-img {
  width: 20%;
}

.dash-blog-img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.dash-details-and-new {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dash-blog-container {
  width: 80%;
}

.dash-blog-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.dash-blog-detail h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

.dash-new-show {
  width: 20%;
  text-align: center;
  margin-top: 2rem;
}

.dash-new-show h2 {
  font-size: 0.8rem;
  background: rgba(7, 184, 24, 1);
  border-radius: 3px;
  padding:  0.8rem 0.5rem 0.8rem 0.5rem;
  width: 7rem;
  border-radius: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-authot-details {
  display: flex;
}

.dash-blog-likes-and-comment {
  display: flex;
  gap: 2rem;
}

.name-and-date h6 {
  margin: 0;

}
.blog-recommend-container{
  display: flex;
  gap: 1rem;
}

.blog-area {
  background: rgba(236, 230, 242, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-left: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  margin-right: 2rem;
}
.blog-area-header{
  display: flex;
gap: 2rem;  
align-items: center;

}
.blog-area-header-details-container h1{
  
  font-size: 30px;
  font-weight: 500;

  
}
.blog-area-header-details-container p{

font-size: 18px;
font-weight: 300;
width: 52%;
text-align: start;
}
.blog-area-header-search-filter-container{
  display: flex;
  gap: 1rem;
}