.booking-details-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}
.booking-details-meeting-details-right{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}
.booking-details-body {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  /* width: 50rem; */
  /* height: 30rem; */
}
.booking-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}
.booking-details-header h1{
font-size: 20px;
font-weight: 400;
margin: 0;
}
.booking-details-header-border-line {
  background-color: rgba(197, 179, 213, 1);
  height: 1px;
}
.booking-details-container-body-mentor-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.booking-details-container-body {
  display: flex;
  gap: 1rem;
  padding: 2rem;
}
.booking-details-container-body-mentor-details h1 {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
}
.booking-details-container-body-mentor-details h2 {
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}
.booking-details-container-body-mentor-details h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.booking-details-container-body-mentor-details li {
  font-size: 14px;
  font-weight: 400;
}
.booking-details-container-body-meeting-details {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(68, 9, 120, 1);
  background: rgba(236, 230, 242, 1);
  box-shadow: 0px 1px 2px 0px rgba(68, 9, 120, 0.15);
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 1rem;
}
.booking-details-container-body-meeting-details header {
  border-bottom: 1px solid rgba(197, 179, 213, 1);
}
.booking-details-container-body-meeting-details h1 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(68, 9, 120, 1);
}
.booking-details-container-body-meeting-date-time-duration-details p {
  font-size: 18px;
  font-weight: 400;
}
.booking-details-container-body-meeting-attendees-details {
  display: flex;
  flex-direction: column;
}
.booking-details-container-body-meeting-attendees-details h3 {
  font-size: 18px;
  font-weight: 700;
}
.booking-details-container-body-meeting-attendees-details p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
.booking-details-container-reschedule-button {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 1);
  width: 20rem;
  height: 3rem;
  display: flex;
  align-items: center;
font-size: 16px;
font-weight: 700;
justify-content: center;
border-radius: 1rem;
cursor: pointer;
}
