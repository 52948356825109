.tandc-container *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.tandc-container{

    .bac-assessment-header {
    background: #b0e4dd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;

    .image-title {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      gap: 0.8rem;
      img {
        width: 12%;
      }
    }
  }
  .about-test-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    width: 90%;
    margin: 0 auto;
    gap: 0.8rem;

    .topic-assessment{
        .assessment{
            color: #007F6D;
        }
    }

    .message{
        font-size: 0.8rem;
    }
    .assessment-instructions{
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        li{
            list-style-type:decimal;
            margin-left: 1rem;
            margin-top: 0.7rem;
        }
        .policy{
            display: flex;
            align-items: center;
            gap: 0.4rem;
            .checkbox input{
                padding: 0.8rem;
            }
        }
    }
    .start-test{
        background:#02720d;
        width: fit-content;
        color: #fff;
        padding: 0.4rem 1rem;
        border-radius: 0.4rem;
        cursor: pointer;
    }
  }
}