.exam-card {
  background: #fff;
  padding: 1rem;
  border-radius: 0.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  box-shadow: 0px 4px 10px 0px #00000040;
  // border-radius: 20px;
  transition: transform 0.3s ease-in-out;
  gap: 1rem;
  width: 18%;
  height: 28vh;
  overflow: hidden;

  .image-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // gap: 0.8rem;
    margin-top: 1rem;
    font-size: 16px;

    // width: 48%;

    img {
      width: 100%;
      height: 16vh;
      overflow: hidden;

    }
  }

  .continue-button {
    background: #02720d;
    color: #fff;
    padding: 0.8rem 0.8rem;
      width: 100%;
    text-align: center;
    // border-radius: 0.4rem;
    cursor: pointer;
    font-weight: 400;
  }


  .subscribe-button {
    background: #043D4D;
    color: #fff;
    padding: 0.8rem 0.8rem;
    width: 100%;
    text-align: center;
    // border-radius: 20px;
    cursor: pointer;
  }
}