.session-history-page {
  display: flex;
  gap: 1rem;
  padding: 2rem;
  flex-direction: column;
  background: rgba(250, 250, 250, 1);

  &-body {
    display: flex;
    gap: 5rem;
  }

  .session-history-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .session-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: linear-gradient(
      45deg,
      #ffffff 2.67%,
      #ffffff 51.62%,
      #fff2e6 97.33%
    );
    padding: 1rem;
    border-radius: 1rem;
    width: 37rem;

    .session-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-time {
          display: flex;
          gap: 0.5rem;

          img {
            width: 1.5rem;
            height: 1.5rem;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
          }
        }

        .session-details-date {
          font-size: 14px;
          font-weight: 400;
          padding: 0.2rem;
          background: rgba(197, 179, 213, 1);
          border-radius: 0.5rem;
        }
      }

      .session-mentor-details {
        display: flex;
        gap: 1rem;

        h3 {
          font-size: 30px;
          font-weight: 500;
          margin: 0;
        }

        p {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    button {
      border: none;
      background: rgba(7, 184, 24, 1);
      height: 3rem;
      font-size: 16px;
      font-weight: 700;
      color: white;
      border-radius: 1rem;
      cursor: pointer;
    }
  }

  .session-history-details-container {
    &.visible {
      display: block;
    }

    .session-details-panel {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      box-shadow: 0px 2px 5px 0px rgba(68, 9, 120, 0.15);
      border-radius: 1rem;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        background: rgba(227, 218, 235, 1);
        border-radius: 1rem 1rem 0 0;

        h3 {
          font-size: 30px;
          font-weight: 500;
        }

        button {
          border: none;
          background: rgba(7, 184, 24, 1);
          height: 3rem;
          width: 10rem;
          border-radius: 1rem;
          font-size: 16px;
          font-weight: 700;
          color: white;
        }
      }

      &-body {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;

        .session-details-panel-second-container {
          display: flex;
          gap: 1rem;

          &-time-details {
            border: 1px solid rgba(0, 0, 0, 0.5);
            padding: 1rem;
            border-radius: 1rem;
            // width: 17rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &-dates {
              display: flex;
              gap: 3rem;

              p {
                display: flex;
                font-size: 14px;
                font-weight: 500;

                .bold-session {
                  font-size: 14px;
                  font-weight: 700;
                }
              }
            }
.attendees-heading{
  
font-size: 14px;
font-weight: 500;
margin: 0;

}
            &-time {
              display: flex;
              gap: 3rem;

              p {
                font-size: 14px;
                font-weight: 500;
              }
            }

            &-duration {
              background: rgba(230, 246, 244, 1);
              border-radius: 1rem;
              font-size: 14px;
              font-weight: 400;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 4rem;
              height: 2rem;
            }

            &-attendees {
              display: flex;
              flex-direction: column;
              align-items: start;
              gap: 0;

              li {
                font-size: 14px;
                font-weight: 700;
              }
            }
          }

          &-counselling-details {
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.5);
            padding: 1rem;
            border-radius: 1rem;
            flex-direction: column;
            gap: 1rem;
            // width: 32rem;

            &-mentor {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            p {
              font-size: 14px;
              font-weight: 500;
              display: flex;
              flex-direction: column;
              align-items: start;

              .bold-session {
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }

        .session-history-description-container {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          align-items: start;
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 1rem;

          ul {
            display: flex;
            flex-direction: column;
            align-items: start;
          }
        }
        .bold-session-time {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}
