.resume-course-card-container{
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(213, 197, 179, 1);
    border-radius: 20px;
    /* gap: 48rem; */
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80rem;
}
.chapter-card-text-container h3{
    font-size: 30px;
    font-weight: 500;
color: rgba(0, 0, 0, 0.5);
margin: 0;
}
.chapter-card-text-container p{
    font-size: 20px;
    font-weight: 400;
    
}