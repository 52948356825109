.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 20px ;
  }
  
  .modal-header {
   padding-top: 1.5rem;
   padding-left: 2rem;
   padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(197, 179, 213, 1)
  }
  
  .modal-close {
    cursor: pointer;
  }
  .status-details-card-right-container h2 {
    margin: 0;
  }
  
  .modal-body {
    margin-top: 20px;
    padding: 1rem;
    display: flex;
   flex-direction: column;
   padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 4rem;
  }
  .status-modal-body-details-containers{
    display: flex;
    gap: 13rem;
  }
  
  .status-box {
    padding: 10px;
    border-radius: 5px;
    color: white;
    text-align: center;
  }
  
  .modal-header-company-role-details{
    display: flex;
    flex-direction: column;
  }
  .modal-header-company-role-details h2{
   margin: 0;
font-size: 16px;
font-weight: 400;

  }
  .modal-header-company-role-details p{
   
    font-size: 18px;
    font-weight: 700;
    
  }
  .status-progress {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-left: 3rem;
  }
  
  .status-progress-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: -2rem;
  }
  
  
  
  .status-progress-item-title {
    margin-right: 10px;
   
font-size: 13px;
font-weight: 500;
color: rgba(70, 86, 104, 1);

  }
  
  .status-progress-item-tick {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    background-color: transparent;
  }
  .status-line{
    background-color: rgb(161, 174, 190);
    height: 4rem;
    width: 0.2rem;
    position: relative;
    top: -1.2rem;
    left: 1.2rem;
  }
  .status-progress-item-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .numbered-box {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; /* Adjust the font size as needed */
  }

  /* right container */

  .status-details-card-right-container{
    display: flex;
    flex-direction: column;
  }
  .status-details-card-salary-amount-container{
    display: flex;
    flex-direction: column;
  }
  .status-details-card-salary-image-amount-box, .status-details-card-salary-industry-employment-contact-boxes{
    display: flex;
    gap: 1rem;
  }
  .status-details-card-salary-image-amount-box h1{
   
    font-size: 35px;
    font-weight: 500;
    margin: 0;
    
  }
  .status-details-card--sub-container h1{
    font-size: 17.5px;
    font-weight: 600;
     color: rgba(42, 24, 3, 1);

  }
  .status-details-card--sub-container p{
    font-size: 15.91px;
    font-weight: 400;
    text-align: start;
  }
  .status-details-card--sub-container{
    display: flex;
    flex-direction: column;
  }
  .status-details-card-salary-industry-employment-contact-container{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
