.bookmark-classes-container {
  width: 60vw;
  height: 100%;
  /* margin-top: 2rem; */
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bookmark-heading {
  display: flex;
  font-size: 1.3rem;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.bookmarked-class-name {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem;
  align-self: center;
  cursor: pointer;
}

.bookmark-classes-and-name {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hashtags-and-class {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.class-hastags {
  padding: 0.3rem 0.5rem;
  background-color: #6cd6865e;
  color: #3fa959;
  border-radius: 10px;
}

.class-hastags p {
  font-size: 1rem;
}

.bookmarked-classes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* chat bar */

.subject-chat-and-tags {
  width: 70%;
  align-self: center;
  position: sticky;
  z-index: 1111;
}

.subject-chat-bar {
}

.subject-tags-link {
  align-self: center;
  width: 80%;
  border: 1px solid;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  /* position: absolute; */
  top: -10px;
  left: 5%;
  padding: 0.5rem;
  border-bottom: none;
  /* margin-bottom: 2rem; */
  z-index: 222;
  transform: translateY(37%) translateX(5%);
}

.bookmark-tags-link Link p {
  font-size: 0.9rem;
  
}

.tags-para{
    border: 1px solid;
    padding: .3rem;
    border-radius: 10px;
}

