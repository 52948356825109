

.chapters-detailed-container{
    width: 61%;
    border-radius: 20px;
    /* box-shadow: 3pox 3px 5px rgba(0, 0, 0, .2); */
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    align-items: start;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
    margin: 1rem 0;
    cursor: pointer;
    
}

.course-chapters-continue-button{
    /* width: Fixed (168px)px;
height: Fixed (50px)px; */
padding: 10.6px 12px 10.6px 12px;

font-size: 18px;
font-weight: 700;


border-radius: 11.7px;
opacity: 0px;
background: rgba(7, 184, 24, 1);
color: white;
}

.chapter-name-and-open{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chapter-icon-and-details{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chapter-icon{
    width: 2rem;
    height: 2rem;
    background-color: #D6E9E1;
    border-radius: 29%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .3em;
}

.chapter-icon img{
    width: 70%;
    height: 70%;
    object-fit: cover;
}

.chapter-name-and-title h3, p{
    margin: 0;
   
    font-size: 22px;
    font-weight: 600;

    
}
.hide-chapter-details-text{
   
font-size: 18px;
font-weight: 700;


}
.chapter-name-open-chapter-seperation{
    border: 1px solid rgb(181 181 181);
   
    width: 96%;
}
.open-chapter{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97%;
}

.chapters-type{
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
}



.bulb-img{
    position: absolute;
    left: -3rem;
    top: 10%;
}

.globe-img{
    position: absolute;
    left: -3rem;
    top: 50%;
}

.bulb-img  img{
    width: 2rem;
}

.globe-img img{
    width: 2.5rem;
}


.chapter-type-and-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #3F3D56;
    padding: 2rem 3rem;
    border-radius: 20px;
    gap: 1rem;
}
.chapter-type-img{
    width: 2rem;
    height: 2rem;
    background-color: #D6E9E1;
    border-radius: 50%;
    padding: 1rem;
}

.chapter-type-img img{
    max-width: 2em;
}
.chapters-name{
    display: flex;
    padding-bottom: 4rem;
    background: rgba(31, 4, 54, 1);
    padding-left: 16rem;
    padding-top: 5rem;
    flex-direction: column;
    height: 27rem;

}
.chapter-body-chapter-card{
    margin-top: -8rem;
}
.chapters-name p{ 
font-size: 20px;
font-weight: 400;
color: white;
text-align: start;
width: 50%;
}
.chapters-name h1{
    margin: 0;
font-size: 16px;
font-weight: 700;
color: rgba(0, 255, 132, 1);
text-transform: uppercase;
}
.chapters-name h2{
    margin: 0;
font-size: 44px;
font-weight: 500;
color: white;

}
.course-all-chapters-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 69%;
    gap: 2rem;
}
.chapter-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    background: rgba(250, 250, 250, 1);
    padding-bottom: 5rem;
}
.chapter-page-user-rating-section-heading
{
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    
}
.course-all-chapters-details-container{
    display: flex;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(235, 227, 218, 1);
    border-radius: 22px;
    padding: 1rem;
    border-radius: 22px;
    align-items: center;
}
.course-all-chapters-individual-container{
    display: flex;   
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.course-all-chapters-details-text-container{
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.course-all-chapters-details-text-container h4{
    margin: 0;
    font-size: 30px;
    font-weight: 500;
}
.course-all-chapters-details-text-container p{
    margin: 0;
    font-size: 18px;
    font-weight: 400;
text-align: start;
}

.chapter-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chapter-progress-item {
    position: relative;
    margin-top: -8rem;
  }
  
  .chapter-progress-circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px  linear-gradient(90.84deg, #009883 0.07%, #784409 17.13%, #440978 27.11%, #FF7B00 43.08%, #E86D0F 72.02%, #0A9DC5 96.37%);
    background: rgba(255, 167, 84, 1);

    
  }
  
  .chapter-progress-line {
    position: absolute;
    width: 2rem;
    height: 13rem;
    background-color: rgba(255, 242, 230, 1);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .chapter-progress-circle.active {
    background-color: rgba(255, 167, 84, 1); /* Active color */
  }
  
  .chapter-progress-line.active {
    background-color: rgba(255, 167, 84, 1); /* Active color */
  }
  .chapter-page-certificate-section, .chapter-page-comment-section, .chapter-page-user-rating-section{
    width: 69%;
  }

  @media (max-width: 1366px)and (max-height: 768px) {
    .chapters-name {
        padding-left: 11rem;    
    }
  
    .chapter-body-chapter-card{
        margin-top: -5rem;
    }
    .chapter-progress-line {
       
        height: 16rem;
       
    }
}