.program-container {
  font-family: "Raleway", sans-serif;

  /* border: 1px solid;
  /* width: 80%; */
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.title-image-card {
  display: flex;
  padding-bottom: 4rem;
  background: rgba(31, 4, 54, 1);
  padding-left: 16rem;
  padding-top: 5rem;
}
.title-card {
  flex: 1 1;
  margin: 0;
  border-radius: 20px;
  padding: 1em;
  width: 55%;

  font-weight: 900;
  font-size: 16px;
color: white;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
/* .title-card h1 {
  padding: 1em;
} */
.title-card > img {
  width: 20%;
  margin-left: 2em;
}
.title-card p{
  width: 50%;
  font-size: 20px;
  font-weight: 400;
text-align: start;
  
}
.title-card h1{

font-size: 44px;
font-weight: 500;


}
.title-card h3{
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 255, 132, 1);
text-transform: uppercase;
}

.program-img {
  margin: 0 0;
  padding: 0;
  flex: 1 1;
  width: 40%;
  /* width: 60vw; */
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program-img img {
  max-width: 100%;

  object-fit: cover;
  margin: 1rem;
}

.course-title-and-join {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prgrm-title {
  font-size: 22px;
}

.program-desc {
  
  /* border: 1px solid; */
}

.program-desc-para {
  text-align: start;
  font-size: 14px;
}

.logo-and-instructor img {
  width: 50px;
}

.logo-and-instructor {
  
  /* border: 1px solid; */
}

.logo-inst {
  /* border: 1px solid; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateX(-12px);
}

.logo-inst p {
  font-size: 12px;
  text-decoration: underline;
}
.course-program-chapter-details{
  display: flex;
  justify-content: center;
}
.program-details {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.program-details p{
  width: 42%;
  text-align: start;
 
font-size: 24px;
font-weight: 400;


}
.program-duration-fees-skills-container{
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(213, 197, 179, 1);
  background: rgba(255, 255, 255, 1);
padding: 2rem;
border-radius: 1rem;
margin-top: -4rem;
/* width: 69%; */
}

.program-duration-fees {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-container {
  padding: 1rem;
  border: 1px solid rgba(235, 227, 218, 1);
  border-radius: 20px;
  width: 71%;
 
}

.learning-box {

  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.learning-list ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.learning-list ul li {

font-size: 20px;
font-weight: 400;
  list-style: none;


}

.learning-box h4{
  margin: 0;

font-size: 20px;
font-weight: 600;


}


.learning-list {
  position: relative;
}

/* .girl-svg {
  position: absolute;
  transform: rotateY(45deg);
  transform-origin: center;
  top: -169px;
  left: -50px; */
/* } */

.girl-svg img {
  width: 139px;
  position: absolute;
  bottom: -25px;
  right: -49px;
  transform: rotateY(176deg);
}

.skills-gain {
  
  margin-top: 2rem;
}

.skills-tags {
  border: 1px solid;


  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 14px;
  background: rgba(250, 250, 250, 1);
  font-size: 10px;
  padding: 1rem;
font-size: 18px;
font-weight: 400;


}
.program-container-download-enroll-buttons{
  display: flex;
  gap: 0.5rem;
  align-items: center;

}
.program-container-download-button{
  /* width: 10rem; */
height: 3rem;
padding-left: 0.5rem;
padding-right: 0.5rem;
border-radius: 9px ;
background: rgba(255, 255, 255, 1);
color: black;
display: flex;
align-items: center;
justify-content: center;
font-size: 16px;
font-weight: 700;
cursor: pointer;
}
.course-program-details-page-body{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background: rgba(250, 250, 250, 1);
    padding-bottom: 5rem;
}

.course-program-details-mentor-container,
.course-program-details-certification-container,
.course-program-details-rating-container,
.course-program-details-comment-container{
  width: 75%;
}




/* responsive design */

@media (max-width: 850px) {
  .title-image-card {
    border: 1px solid;
    background-color: #f4f7f2;
    margin: 0 10em;
    border-radius: 20px;
    /* height: 200px; */
  }
  .title-card {
    margin: 0;
    border-radius: 20px 0px 0xp 20px;
    padding: 1em;
    background-color: #f4f7f2;
    font-weight: 900;
    font-size: 16px;
    color: darkblue;
    display: none;
  }

  .program-img {
    margin: 0 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .program-img img {
    max-width: 100%;
    /* height: 100vh; */
    /* background-repeat: repeat; */
    object-fit: cover;
  }

  .program-duration-fees {
    /* border: 1px solid; */
    width: 60vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .course-title-and-join {

    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(213, 197, 179, 1)
  }

  .prgrm-title {
    font-size: 1.2;
  }

  .program-desc {
    width: 90vw;
    /* border: 1px solid; */
  }

  .logo-and-instructor img {
    width: 50px;
  }

  .logo-and-instructor {
    width: 90vw;
    /* border: 1px solid; */
  }

  .logo-inst {
    /* border: 1px solid; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(-12px);
  }

  .logo-inst p {
    font-size: 0.8rem;
    text-decoration: underline;
  }

  .program-desc-para {
    width: 90vw;
    text-align: start;
    font-size: 14px;
  }

  .program-details {
    width: 90vw;
  }

  .learning-container {
    border: 1px solid;
    border-radius: 20px;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }

  .learning-box {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .learning-list ul {
    /* border: 1px solid; */
    display: flex;
    gap: 0;
  }

  .learning-list ul li {
    list-style: none;
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }

  .learning-list ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #3fa959; /* Change the color */
    font-size: 20px;
    text-align: center;
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

  .learning-list {
    position: relative;
  }

  .skills-gain {
    width: 90vw;
    margin-top: 2rem;
  }

  .skill-shortcuts {
    width: 90vw;
  }
}
