.form-user-inputs {
  border-radius: 20px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
  padding: 0.8rem;
  position: relative;
  padding-left: 2rem;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.form-user-inputs p {
  font-weight: 100;
  margin: 0;
}

.user-chat-img {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #349367;
  border-radius: 50%;
  overflow: hidden;
}

.user-chat-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.input-box-color {
  width: 0.8rem;
  height: 100%;
  background-color: #2f2e41;
  position: absolute;
  left: 0;
  top: 0;
}

.chat-response-box {
  padding: 1rem;
  padding-left: 3rem;
  overflow: hidden;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 20px;
  background-color: white;
}

.chat-box-color {
  width: 0.8rem;
  height: 100%;
  background-color: #349367;
  position: absolute;
  left: 0;
  top: 0;
}

.chat-response-actions {
  width: 2rem;
  height: 100%;
  background-color: #3493671a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  top: 0;
  right: 0;
}

.chat-response-actions i {
  cursor: pointer;
}
