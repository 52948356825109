.questionPaper-home * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .exams-subscribed-container {
    background: #c5b3d5;
    box-shadow: 0px 4px 10px 0px #4409781a;
    padding: 1rem;
    width: 96%;
    margin: 2rem auto;
    border-radius: 0.4rem;

    .title-viewAll {
      display: flex;
      align-items: center;
      justify-content: space-between;
  

      h2 {
        font-weight: 400;
      }

      div {
        background: #02720d;
        padding: 0.4rem 0.8rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }
    .subscribed-card-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      margin-top: 1rem;

    }
  }
  