.papersubject-container{
    padding: 1rem;

    
}

.year-switch button{
    padding: 0.4rem;
    border-radius: 0.4rem;
    background:  #fff;
    border: 1.5px solid #C5B3D5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 0.8rem;
    color: #00000090;
    cursor: pointer;
}
.year-switch{
    display: flex;
    justify-content: space-between;
}
.year{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.year button{
    background: #fff;
    border: 1px solid;
    color: #000;
    padding: 0.4rem 1rem;
    border-radius: 0.4em;
    cursor: pointer;
    font-size: 1rem;
}
.year button:hover{
    background: #44097849;
    color: #fff;
}
.year button.active{
    background: #02720d;
    color: #fff;
}
.switch-button{
    background: #ECE6F2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    padding: 0.4rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: flex-direction 0.5s ease-in-out;
}
.switch-button.active{
    flex-direction: row-reverse;
}

.switch-button .circle{
    background: #440978;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
    
}
.switch-button.active .circle {
    transform: translateX(10%);
}


.switch-button span{
    font-size: 0.8rem;
}
.subjectContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}
.subjectContainer .link{
    width: 16%;
}


.topicContainer{
    margin-top: 2rem;
}
.topicContainer ul{
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.8rem;
}
.topicContainer li{
    border-radius: 0.4rem;
    border: 0.81px solid #C5B3D5;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.8rem;
}
.topicContainer .band{
    background: #0BAEDB;
    width: 2%;
    height: 4rem;
    border-radius: 0.4rem 0 0 0.4rem;
}
.topic-name{
    display: flex;
    gap: 0.4rem;
    width: 60%;
    height: 100%;
    align-items: center;
}
.subject-color-code{
    height: inherit;
    width: 4%;
    border-radius: 0.4rem 0 0 0.4rem;
}
.question-chance{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.8rem;
}
.question-chance p:nth-child(2){
    font-size: 0.6rem;
    color: #02720d;
}
.search-filter{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.search-filter input[type="text"]{
    padding: 0.8rem;
    width: 40%;
    border-radius: 2rem;
    border: 1px solid #C5B3D5;
}

.search-filter input::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 0.5; /*Change the opacity between 0 and 1*/
}
.search-filter input:focus {
  outline: none;
}

.search-filter button{
    padding: 0.4rem;
    border-radius: 0.4rem;
    background:  #fff;
    border: 1.5px solid #C5B3D5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 0.8rem;
    color: #00000090;
    cursor: pointer;
}

.subject-filter{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.subject-filter .programs
                  {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.programs .subject-tag{
    background: #fff;
    border: 1px solid;
    color: #000;
    padding: 0.4rem 1rem;
    border-radius: 0.4em;
    cursor: pointer;
    font-size: 1rem;
}
.programs .subject-tag.active{
    color: #fff;
    background: #02720d;
}